import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.colors.neutralLighter,
    fontSize: 20
  }
}));

export const NotAvailable = () => {
  const classes = useStyles();

  return <Typography className={classes.root}>Not available on the current network.</Typography>;
};
