import { BigNumber } from "ethers";
import { useMemo } from "react";
import { LotteryResponse, LotteryRound, LotteryRoundUserTickets } from "types";

export const useProcessLotteryResponse = (
  lotteryData: LotteryResponse & { userTickets?: LotteryRoundUserTickets }
): LotteryRound => {
  const {
    priceTicketInPlay: priceTicketInPlayAsString,
    discountDivisor: discountDivisorAsString,
    amountCollectedInPlay: amountCollectedInPlayAsString
  } = lotteryData;

  const discountDivisor = useMemo(() => {
    return BigNumber.from(discountDivisorAsString || "0");
  }, [discountDivisorAsString]);

  const priceTicketInPlay = useMemo(() => {
    return BigNumber.from(priceTicketInPlayAsString || "0");
  }, [priceTicketInPlayAsString]);

  const amountCollectedInPlay = useMemo(() => {
    return BigNumber.from(amountCollectedInPlayAsString || "0");
  }, [amountCollectedInPlayAsString]);

  return {
    isLoading: lotteryData.isLoading,
    lotteryId: lotteryData.lotteryId,
    userTickets: lotteryData.userTickets,
    status: lotteryData.status,
    startTime: lotteryData.startTime,
    endTime: lotteryData.endTime,
    priceTicketInPlay,
    discountDivisor,
    treasuryFee: lotteryData.treasuryFee,
    firstTicketId: lotteryData.firstTicketId,
    lastTicketId: lotteryData.lastTicketId,
    amountCollectedInPlay,
    finalNumber: lotteryData.finalNumber,
    playPerBracket: lotteryData.playPerBracket,
    countWinnersPerBracket: lotteryData.countWinnersPerBracket,
    rewardsBreakdown: lotteryData.rewardsBreakdown
  };
};
