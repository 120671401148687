import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { matchPath, NavLink, useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import CloseIcon from "@material-ui/icons/Close";
import { AccountInfo } from "../AccountInfo";
import { transparentize } from "polished";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ETHER_DECIMAL, SVG_ICONS } from "config/constants";
import { usePricePlay } from "helpers";
import { formatBigNumber } from "utils";
import LaunchIcon from "@material-ui/icons/Launch";
const useStyles = makeStyles(theme => ({
  root: {},
  navContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end"
  },
  navBar: {
    backgroundColor: theme.colors.neutral
  },
  navbarWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  menu: {
    marginLeft: 16,
    color: theme.colors.neutralLighter
  },
  menuIcon: {
    color: theme.colors.neutralLighter
  },
  closeIcon: {
    color: theme.colors.neutralLighter,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  navItems: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(10)
    }
  },
  navItem: {
    color: theme.colors.neutralLighter,
    textDecoration: "none",
    transition: "all 0.4s",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "6px 12px",
    fontSize: 16,
    "&:last-child": {
      marginRight: 16
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 12px"
    },
    "& svg": {
      // width: 24,
      // height: 24,
      // fill: "currentColor",
      // marginRight: 16,
    },
    "&:hover": {
      color: transparentize(0.2, theme.colors.primary)
    },
    "&:hover svg": {
      color: transparentize(0.2, theme.colors.primary)
    },
    "&.active": {
      color: theme.colors.primary,
      fontWeight: 700
    }
  },
  navbarLogoWrapper: {
    padding: "16px 0",
    textAlign: "center"
  },
  navbarLogo: {
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: 40
    }
  },
  navLink: {
    textDecoration: "none",
    color: theme.colors.neutralLighter,
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: theme.colors.primary,
      borderBottom: "1px solid white"
    }
  },
  playPrice: {
    color: theme.colors.primary,
    fontWeight: 600
  },

  icon: {
    color: "white"
  },
  mobileNavbar: {},
  list: {
    width: 250,
    background: theme.colors.neutral,
    height: "100%"
  },
  launchIcon: {
    fontSize: 12,
    marginLeft: 5,
    color: transparentize(0.3, theme.colors.neutralLighter)
  }
}));

const items = [
  // { id: "wallet", title: "Wallet", href: "/", icon: SVG_ICONS.wallet },
  // {
  //   id: "lottery",
  //   title: "Lottery",
  //   href: "/lottery",
  //   icon: SVG_ICONS.lottery,
  // },
  // {
  //   id: "ido-claims",
  //   title: "IDO's",
  //   href: "/ido-claims",
  //   icon: SVG_ICONS.claim,
  // },
  {
    id: "staking",
    title: "Staking",
    href: "/staking",
    icon: SVG_ICONS.staking
  }
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   href: "/calendar",
  //   icon: SVG_ICONS.staking,
  // },
  // {
  //   id: "learnmore",
  //   title: "Learn More",
  //   href: "/learn-more",
  //   icon: SVG_ICONS.staking,
  // },
  // {
  //   id: "tournament",
  //   title: "Tournament",
  //   href: "/tournament",
  //   icon: SVG_ICONS.tournament,
  // },
  // {
  //   id: "entrylock",
  //   title: "Entry Lock",
  //   href: "/entry-lock",
  //   icon: SVG_ICONS.entryLock,
  // },
  // {
  //   id: "link-sol",
  //   title: "Link Sol",
  //   href: "/link-sol",
  //   icon: SVG_ICONS.solana,
  // },
  // {
  //   id: "play-pad",
  //   title: "PlayPad",
  //   href: "/play-pad",
  //   icon: SVG_ICONS.playPad,
  // },
  // {
  //   id: "sports pools",
  //   title: "Sports Pools",
  //   href: "/sports-pools",
  //   icon: SVG_ICONS.league,
  // },
  // {
  //   id: "polybet",
  //   title: "Polybet",
  //   href: "https://polybet.net/",
  //   externalLink: true,
  // },
  // {
  //   id: "quizdrop",
  //   title: "Quizdrop",
  //   href: "https://quizdrop.net/",
  //   externalLink: true,
  // },
];

interface IState {
  navbarVisible: boolean;
}

export const Header = () => {
  const classes = useStyles();
  const [state, setState] = useState<IState>({ navbarVisible: false });
  const history = useHistory();
  const bnbbPrice = usePricePlay();
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const setNavbarVisible = (navbarVisible: boolean) => {
    setState(prev => ({ ...prev, navbarVisible }));
  };

  useEffect(() => {
    setNavbarVisible(false);
  }, [history.location.pathname]);

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.navContent)}>
        <AppBar position="static" className={classes.navBar}>
          <CssBaseline />
          <Toolbar className={classes.navbarWrapper}>
            <div className={classes.navbarLogoWrapper}>
              <NavLink to="/">
                <img alt="logo" className={classes.navbarLogo} src="/assets/logo.svg" />
              </NavLink>
            </div>
            <Box className={classes.navItems}>
              {isMobile ? (
                <>
                  <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} className={classes.mobileNavbar}>
                    <div className={classes.list}>
                      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.closeIcon}>
                        <CloseIcon />
                      </IconButton>
                      <List>
                        {items.map(item => {
                          return (
                            <ListItem onClick={() => setOpenDrawer(false)} key={item.id} className={classes.navItem}>
                              <ListItemText>
                                {/* {item.externalLink ? (
                                  <a
                                    className={classes.navItem}
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.title}
                                    <LaunchIcon
                                      style={{ fontSize: 12, marginLeft: 5 }}
                                    />
                                  </a>
                                ) : (
                                  <NavLink
                                    className={classes.navItem}
                                    to={item.href}
                                    isActive={() =>
                                      !!matchPath(history.location.pathname, {
                                        path: item.href,
                                        exact: item.id === "wallet",
                                      })
                                    }
                                  >
                                    {item.title}
                                  </NavLink>
                                )} */}
                                <NavLink
                                  className={classes.navItem}
                                  to={item.href}
                                  isActive={() =>
                                    !!matchPath(history.location.pathname, {
                                      path: item.href,
                                      exact: item.id === "wallet"
                                    })
                                  }
                                >
                                  {item.title}
                                </NavLink>
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                      <ListItem>
                        <AccountInfo />
                      </ListItem>
                    </div>
                  </Drawer>
                  <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.menuIcon}>
                    <MenuIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <div className={classes.navItems}>
                    {items.map(item => {
                      // return item.externalLink ? (
                      //   <a
                      //     className={classes.navItem}
                      //     key={item.id}
                      //     href={item.href}
                      //     target="_blank"
                      //     rel="noopener noreferrer"
                      //   >
                      //     {item.title}
                      //     <LaunchIcon className={classes.launchIcon} />
                      //   </a>
                      // ) : (
                      //   <NavLink
                      //     className={classes.navItem}
                      //     key={item.id}
                      //     to={item.href}
                      //     isActive={() =>
                      //       !!matchPath(history.location.pathname, {
                      //         path: item.href,
                      //         exact: item.id === "wallet",
                      //       })
                      //     }
                      //   >
                      //     {item.title}
                      //   </NavLink>
                      // );
                      return (
                        <NavLink
                          className={classes.navItem}
                          key={item.id}
                          to={item.href}
                          isActive={() =>
                            !!matchPath(history.location.pathname, {
                              path: item.href,
                              exact: item.id === "wallet"
                            })
                          }
                        >
                          {item.title}
                        </NavLink>
                      );
                    })}
                  </div>
                  <AccountInfo />
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </div>
      {/* <>
        <div
          className={clsx(
            classes.mobileNavbarBg,
            state.navbarVisible ? "visible" : ""
          )}
          onClick={() => setNavbarVisible(false)}
        />
        <div
          className={clsx(
            classes.sidebar,
            state.navbarVisible ? "visible" : ""
          )}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className={classes.sidebarContent}>
            <div className={classes.navbarLogoWrapper}>
              <img
                alt="plogo"
                className={classes.navbarLogo}
                src="/assets/plogo.png"
              />
            </div>

            <div className={classes.navContent}>
              {items.map((item) => {
                const Icon = item.icon;
                return (
                  <NavLink
                    className={classes.navItem}
                    key={item.id}
                    to={item.href}
                    isActive={() =>
                      !!matchPath(history.location.pathname, {
                        path: item.href,
                        exact: item.id === "wallet",
                      })
                    }
                  >
                    <Icon />
                    <span>{item.title}</span>
                    <ChevronRightIcon className="navItem__right" />
                  </NavLink>
                );
              })}
              <div className={classes.divider} />
              {bottomItems.map((item) => {
                return (
                  <NavLink
                    className={classes.navLink}
                    key={item.id}
                    to={item.href}
                  >
                    <span>{item.title}</span>
                    <LaunchIcon style={{ fontSize: 16, marginLeft: 5 }} />
                  </NavLink>
                );
              })}
            </div>
            <Typography align="center" className={classes.playPrice}>
              BNBB PRICE: $ {formatBigNumber(playPrice, ETHER_DECIMAL)}
            </Typography>
            <div className={classes.bottomWrapper}>

            </div>
          </div>
        </div>
      </> */}
    </div>
  );
};
