import {
  NUM_ROUNDS_TO_CHECK_FOR_REWARDS,
  NUM_ROUNDS_TO_FETCH_FROM_NODES,
  TICKET_LIMIT_PER_REQUEST
} from "config/constants";
import { DefaultReadonlyProvider, getContractAddress, getGraphInfo } from "config/networks";
import { useConnectedWeb3Context } from "contexts/connectedWeb3";
import React, { useMemo, useState } from "react";
import { LotteryService } from "services/lottery";
import {
  LotteryResponse,
  LotteryRoundGraphEntity,
  LotteryState,
  LotteryTicket,
  LotteryTicketClaimData,
  LotteryUserGraphEntity
} from "types";
import { request, gql } from "graphql-request";
import { LotteryStatus } from "utils/enums";
import {
  applyNodeDataToLotteriesGraphResponse,
  applyNodeDataToUserGraphResponse,
  processRawTicketsResponse,
  processViewLotteryErrorResponse,
  processViewLotterySuccessResponse
} from "pages/LotteryPage/helpers";
import { useProcessLotteryResponse } from "pages/LotteryPage/hooks/useProcessLotteryResponse";
import { BigNumber } from "ethers";
import { ZERO } from "utils/number";

interface RoundDataAndUserTickets {
  roundId: string;
  userTickets: LotteryTicket[];
  finalNumber: string;
}

const initialState: LotteryState = {
  currentLotteryId: null,
  isTransitioning: false,
  maxNumberTicketsPerBuyOrClaim: null,
  currentRound: {
    isLoading: true,
    lotteryId: null,
    status: LotteryStatus.PENDING,
    startTime: "",
    endTime: "",
    priceTicketInPlay: "",
    discountDivisor: "",
    treasuryFee: "",
    firstTicketId: "",
    lastTicketId: "",
    amountCollectedInPlay: "",
    finalNumber: null,
    playPerBracket: [],
    countWinnersPerBracket: [],
    rewardsBreakdown: [],
    userTickets: {
      isLoading: true,
      tickets: []
    }
  },
  lotteriesData: null,
  userLotteryData: { account: "", totalPlay: "", totalTickets: "", rounds: [] }
};

const LotteryContext = React.createContext<
  LotteryState & {
    setLotteryState: (state: LotteryState) => void;
    setLotteryIsTransitioning: (_: { isTransitioning: boolean }) => void;
    fetchCurrentLotteryId: () => Promise<void>;
    fetchPublicLotteries: () => Promise<void>;
    fetchCurrentLottery: () => Promise<void>;
    fetchUserTicketsAndLotteries: () => Promise<void>;
    fetchUserLotteries: (props: { account: string; currentLotteryId: string }) => Promise<void>;
    fetchUnclaimedUserRewards: (
      account: string,
      userLotteryData: LotteryUserGraphEntity,
      lotteriesData: LotteryRoundGraphEntity[]
    ) => Promise<LotteryTicketClaimData[]>;
    fetchLottery: (lotteryId: string) => Promise<LotteryResponse>;
    fetchUserTicketsForOneRound: (account: string, lotteryId: string) => Promise<LotteryTicket[]>;
    getWinningTickets: (roundDataAndUserTickets: RoundDataAndUserTickets) => Promise<LotteryTicketClaimData | null>;
  }
>({
  ...initialState,
  setLotteryState: () => {},
  setLotteryIsTransitioning: () => {},
  fetchCurrentLotteryId: () => new Promise(() => {}),
  fetchPublicLotteries: () => new Promise(() => {}),
  fetchCurrentLottery: () => new Promise(() => {}),
  fetchUserTicketsAndLotteries: () => new Promise(() => {}),
  fetchUserLotteries: () => new Promise(() => {}),
  fetchUnclaimedUserRewards: () => new Promise(() => {}),
  fetchLottery: () => new Promise(() => {}),
  fetchUserTicketsForOneRound: () => new Promise(() => {}),
  getWinningTickets: () => new Promise(() => {})
});

export const useLottery = () => {
  const context = React.useContext(LotteryContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  const maxNumberTicketsPerBuyOrClaimAsString = context.maxNumberTicketsPerBuyOrClaim;
  const maxNumberTicketsPerBuyOrClaim = useMemo(() => {
    return BigNumber.from(maxNumberTicketsPerBuyOrClaimAsString || "0");
  }, [maxNumberTicketsPerBuyOrClaimAsString]);

  return {
    ...context,
    maxNumberTicketsPerBuyOrClaim,
    currentRound: useProcessLotteryResponse(context.currentRound)
  };
};

export const LotteryProvider: React.FC = props => {
  const [state, setState] = useState<LotteryState>(initialState);
  const { account, library: provider, networkId } = useConnectedWeb3Context();
  const lotteryContractAddress = getContractAddress("lottery", networkId);
  const lotteryContract = new LotteryService(provider || DefaultReadonlyProvider, account, lotteryContractAddress);
  const theGraph = getGraphInfo("lottery", networkId);

  const fetchLottery = async (lotteryId: string): Promise<LotteryResponse> => {
    try {
      const lotteryData = await lotteryContract.viewLottery(lotteryId);
      return processViewLotterySuccessResponse(lotteryData, lotteryId);
    } catch (error) {
      return processViewLotteryErrorResponse(lotteryId);
    }
  };

  const fetchCurrentLotteryIdAndMaxBuy = async () => {
    try {
      const [currentLotteryId, maxNumberTicketsPerBuyOrClaim] = await Promise.all([
        lotteryContract.currentLotteryId(),
        lotteryContract.maxNumberTicketsPerBuyOrClaim()
      ]);
      return {
        currentLotteryId: currentLotteryId ? currentLotteryId.toString() : null,
        maxNumberTicketsPerBuyOrClaim: maxNumberTicketsPerBuyOrClaim ? maxNumberTicketsPerBuyOrClaim.toString() : null
      };
    } catch (error) {
      return {
        currentLotteryId: null,
        maxNumberTicketsPerBuyOrClaim: null
      };
    }
  };

  const getGraphLotteries = async (): Promise<LotteryRoundGraphEntity[]> => {
    try {
      const response = await request(
        theGraph.httpUri,
        gql`
          query getLotteries {
            lotteries(first: 100, orderDirection: desc, orderBy: block) {
              id
              totalUsers
              totalTickets
              winningTickets
              status
              finalNumber
              startTime
              endTime
              ticketPrice
            }
          }
        `
      );
      return response.lotteries;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getLotteriesData = async (currentLotteryId: string): Promise<LotteryRoundGraphEntity[]> => {
    const idsForNodesCall = getRoundIdsArray(currentLotteryId);
    const nodeData = await fetchMultipleLotteries(idsForNodesCall);
    const graphResponse = await getGraphLotteries();
    const mergedData = applyNodeDataToLotteriesGraphResponse(nodeData, graphResponse);
    return mergedData;
  };

  const getRoundIdsArray = (currentLotteryId: string): string[] => {
    const currentIdAsInt = parseInt(currentLotteryId, 10);
    const roundIds = [];
    for (let i = 0; i < NUM_ROUNDS_TO_FETCH_FROM_NODES; i++) {
      if (currentIdAsInt - i >= 0) roundIds.push(currentIdAsInt - i);
    }

    return roundIds.map(roundId => roundId.toString());
  };

  const viewUserInfoForLotteryId = async (
    account: string,
    lotteryId: string,
    cursor: number,
    perRequestLimit: number
  ): Promise<LotteryTicket[] | null> => {
    try {
      const data = await lotteryContract.viewUserInfoForLotteryId(account, lotteryId, cursor, perRequestLimit);
      return processRawTicketsResponse(data);
    } catch (error) {
      console.error("viewUserInfoForLotteryId", error);
      return null;
    }
  };

  const fetchUserTicketsForOneRound = async (account: string, lotteryId: string): Promise<LotteryTicket[]> => {
    let cursor = 0;
    let numReturned = TICKET_LIMIT_PER_REQUEST;
    const ticketData = [];

    while (numReturned === TICKET_LIMIT_PER_REQUEST) {
      // eslint-disable-next-line no-await-in-loop
      const response = await viewUserInfoForLotteryId(account, lotteryId, cursor, TICKET_LIMIT_PER_REQUEST);
      if (response) {
        cursor += TICKET_LIMIT_PER_REQUEST;
        numReturned = response.length;
        ticketData.push(...response);
      }
    }

    return ticketData;
  };

  const fetchUserTicketsForMultipleRounds = async (
    idsToCheck: string[],
    account: string
  ): Promise<{ roundId: string; userTickets: LotteryTicket[] }[]> => {
    const ticketsForMultipleRounds = [];
    for (let i = 0; i < idsToCheck.length; i += 1) {
      const roundId = idsToCheck[i];
      // eslint-disable-next-line no-await-in-loop
      const ticketsForRound = await fetchUserTicketsForOneRound(account, roundId);
      ticketsForMultipleRounds.push({
        roundId,
        userTickets: ticketsForRound
      });
    }
    return ticketsForMultipleRounds;
  };

  const fetchMultipleLotteries = async (lotteryIds: string[]): Promise<LotteryResponse[]> => {
    try {
      const multicallRes = await Promise.all(lotteryIds.map(lotteryId => lotteryContract.viewLottery(lotteryId)));

      const processedResponses = multicallRes.map((res, index) => {
        return processViewLotterySuccessResponse(res, lotteryIds[index]);
      });
      return processedResponses;
    } catch (error) {
      console.error(error);
      return lotteryIds.map((lotteryId, index) => processViewLotteryErrorResponse(lotteryIds[index]));
    }
  };

  const getGraphLotteryUser = async (account: string): Promise<LotteryUserGraphEntity> => {
    let user;
    const blankUser = {
      account,
      totalPlay: "",
      totalTickets: "",
      rounds: []
    };

    try {
      const response = await request(
        theGraph.httpUri,
        gql`
          query getUserLotteries($account: ID!) {
            user(id: $account) {
              id
              totalTickets
              totalPlay
              rounds(first: 100, orderDirection: desc, orderBy: block) {
                id
                lottery {
                  id
                  endTime
                  status
                }
                claimed
                totalTickets
              }
            }
          }
        `,
        { account: account.toLowerCase() }
      );
      const userRes = response.user;

      // If no user returned - return blank user
      if (!userRes) {
        user = blankUser;
      } else {
        user = {
          account: userRes.id,
          totalPlay: userRes.totalPlay,
          totalTickets: userRes.totalTickets,
          rounds: userRes.rounds.map((round: any) => {
            return {
              lotteryId: round?.lottery?.id,
              endTime: round?.lottery?.endTime,
              claimed: round?.claimed,
              totalTickets: round?.totalTickets,
              status: round?.lottery?.status
            };
          })
        };
      }
    } catch (error) {
      console.error(error);
      user = blankUser;
    }

    return user;
  };

  const getUserLotteryData = async (account: string, currentLotteryId: string): Promise<LotteryUserGraphEntity> => {
    const idsForTicketsNodeCall = getRoundIdsArray(currentLotteryId);
    const roundDataAndUserTickets = await fetchUserTicketsForMultipleRounds(idsForTicketsNodeCall, account);
    const userRoundsNodeData = roundDataAndUserTickets.filter(round => round.userTickets.length > 0);
    const idsForLotteriesNodeCall = userRoundsNodeData.map(round => round.roundId);

    const lotteriesNodeData = await fetchMultipleLotteries(idsForLotteriesNodeCall);
    const graphResponse = await getGraphLotteryUser(account);
    const mergedRoundData = applyNodeDataToUserGraphResponse(
      userRoundsNodeData,
      graphResponse.rounds,
      lotteriesNodeData
    );
    const graphResponseWithNodeRounds = {
      ...graphResponse,
      rounds: mergedRoundData
    };
    return graphResponseWithNodeRounds;
  };

  const fetchPublicLotteries = async () => {
    const lotteries = await getLotteriesData(state.currentLotteryId || "0");
    setState(prev => ({ ...prev, lotteriesData: lotteries }));
  };

  const fetchCurrentLottery = async () => {
    const lotteryInfo = await fetchLottery(state.currentLotteryId || "");
    setState(prev => ({
      ...prev,
      currentRound: { ...prev.currentRound, ...lotteryInfo }
    }));
  };

  const fetchUserLotteries = async ({ account, currentLotteryId }: { account: string; currentLotteryId: string }) => {
    const userLotteries = await getUserLotteryData(account, currentLotteryId);
    setState(prev => ({ ...prev, userLotteryData: userLotteries }));
  };

  const fetchCurrentLotteryId = async () => {
    const currentIdAndMaxBuy = await fetchCurrentLotteryIdAndMaxBuy();
    setState(prev => ({ ...prev, ...currentIdAndMaxBuy }));
  };

  const fetchUserTicketsAndLotteries = async () => {
    const userLotteriesRes = await getUserLotteryData(account || "", state.currentLotteryId || "");
    const userParticipationInCurrentRound = userLotteriesRes.rounds?.find(
      round => round.lotteryId === state.currentLotteryId
    );
    const userTickets = userParticipationInCurrentRound?.tickets;

    // User has not bought tickets for the current lottery, or there has been an error
    if (!userTickets || userTickets.length === 0) {
      setState(prev => ({
        ...prev,
        userLotteryData: userLotteriesRes,
        currentRound: {
          ...prev.currentRound,
          userTickets: {
            ...prev.currentRound.userTickets,
            isLoading: false,
            tickets: []
          }
        }
      }));
      return;
    }
    setState(prev => ({
      ...prev,
      userLotteryData: userLotteriesRes,
      currentRound: {
        ...prev.currentRound,
        userTickets: {
          ...prev.currentRound.userTickets,
          isLoading: false,
          tickets: userTickets
        }
      }
    }));
  };

  const fetchPlayRewardsForTickets = async (
    winningTickets: LotteryTicket[]
  ): Promise<{
    ticketsWithUnclaimedRewards: LotteryTicket[];
    playTotal: BigNumber;
  }> => {
    const calls = winningTickets.map(winningTicket => {
      const { roundId, id, rewardBracket } = winningTicket;

      return lotteryContract.viewRewardsForTicketId(roundId || "", id, rewardBracket || 0);
    });

    try {
      const playRewards = await Promise.all(calls);

      const playTotal = playRewards.reduce((accum: BigNumber, playReward: BigNumber) => {
        return accum.add(playReward);
      }, ZERO);

      const ticketsWithUnclaimedRewards = winningTickets.map((winningTicket, index) => {
        return { ...winningTicket, playReward: playRewards[index] };
      });
      return { ticketsWithUnclaimedRewards, playTotal };
    } catch (error) {
      return { ticketsWithUnclaimedRewards: [], playTotal: ZERO };
    }
  };

  const getRewardBracketByNumber = (ticketNumber: string, finalNumber: string): number => {
    // Winning numbers are evaluated right-to-left in the smart contract, so we reverse their order for validation here:
    // i.e. '1123456' should be evaluated as '6543211'
    const ticketNumAsArray = ticketNumber.split("").reverse();
    const winningNumsAsArray = finalNumber.split("").reverse();
    const matchingNumbers = [];

    // The number at index 6 in all tickets is 1 and will always match, so finish at index 5
    for (let index = 0; index < winningNumsAsArray.length - 1; index++) {
      if (ticketNumAsArray[index] !== winningNumsAsArray[index]) {
        break;
      }
      matchingNumbers.push(ticketNumAsArray[index]);
    }

    // Reward brackets refer to indexes, 0 = 1 match, 5 = 6 matches. Deduct 1 from matchingNumbers' length to get the reward bracket
    const rewardBracket = matchingNumbers.length - 1;
    return rewardBracket;
  };

  const getWinningTickets = async (
    roundDataAndUserTickets: RoundDataAndUserTickets
  ): Promise<LotteryTicketClaimData | null> => {
    const { roundId, userTickets, finalNumber } = roundDataAndUserTickets;

    const ticketsWithRewardBrackets = userTickets.map(ticket => {
      return {
        roundId,
        id: ticket.id,
        number: ticket.number,
        status: ticket.status,
        rewardBracket: getRewardBracketByNumber(ticket.number, finalNumber)
      };
    });

    // A rewardBracket of -1 means no matches. 0 and above means there has been a match
    const allWinningTickets = ticketsWithRewardBrackets.filter(ticket => {
      return ticket.rewardBracket >= 0;
    });

    // If ticket.status is true, the ticket has already been claimed
    const unclaimedWinningTickets = allWinningTickets.filter(ticket => {
      return !ticket.status;
    });

    console.log("unclaimedWinningTickets", unclaimedWinningTickets);

    if (unclaimedWinningTickets.length > 0) {
      const { ticketsWithUnclaimedRewards, playTotal } = await fetchPlayRewardsForTickets(unclaimedWinningTickets);
      return {
        ticketsWithUnclaimedRewards,
        allWinningTickets,
        playTotal,
        roundId
      };
    }

    if (allWinningTickets.length > 0) {
      return {
        ticketsWithUnclaimedRewards: [],
        allWinningTickets,
        playTotal: ZERO,
        roundId
      };
    }

    return null;
  };

  const getWinningNumbersForRound = (targetRoundId: string, lotteriesData: LotteryRoundGraphEntity[]) => {
    const targetRound = lotteriesData.find(pastLottery => pastLottery.id === targetRoundId);
    return targetRound?.finalNumber;
  };

  const fetchUnclaimedUserRewards = async (
    account: string,
    userLotteryData: LotteryUserGraphEntity,
    lotteriesData: LotteryRoundGraphEntity[]
  ): Promise<LotteryTicketClaimData[]> => {
    const { rounds } = userLotteryData;

    // If there is no user round history - return an empty array
    if (rounds.length === 0) {
      return [];
    }

    // If the web3 provider account doesn't equal the userLotteryData account, return an empty array - this is effectively a loading state as the user switches accounts
    if (userLotteryData.account.toLowerCase() !== account.toLowerCase()) {
      return [];
    }

    // Filter out non-claimable rounds
    const claimableRounds = rounds.filter(round => {
      return round.status.toLowerCase() === LotteryStatus.CLAIMABLE;
    });

    // Rounds with no tickets claimed OR rounds where a user has over 100 tickets, could have prizes
    const roundsWithPossibleWinnings = claimableRounds.filter(round => {
      return !round.claimed || parseInt(round.totalTickets, 10) > 100;
    });

    // Check the X  most recent rounds, where X is NUM_ROUNDS_TO_CHECK_FOR_REWARDS
    const roundsToCheck = roundsWithPossibleWinnings.slice(0, NUM_ROUNDS_TO_CHECK_FOR_REWARDS);

    if (roundsToCheck.length > 0) {
      const idsToCheck = roundsToCheck.map(round => round.lotteryId);
      const userTicketData = await fetchUserTicketsForMultipleRounds(idsToCheck, account);
      const roundsWithTickets = userTicketData.filter(roundData => roundData?.userTickets?.length > 0);

      const roundDataAndWinningTickets = roundsWithTickets.map(roundData => {
        return {
          ...roundData,
          finalNumber: getWinningNumbersForRound(roundData.roundId, lotteriesData)
        };
      });

      const winningTicketsForPastRounds = await Promise.all(
        roundDataAndWinningTickets.map(roundData => getWinningTickets(roundData as any))
      );

      // Filter out null values (returned when no winning tickets found for past round)
      const roundsWithWinningTickets = winningTicketsForPastRounds.filter(
        winningTicketData => winningTicketData !== null
      );

      // Filter to only rounds with unclaimed tickets
      const roundsWithUnclaimedWinningTickets = roundsWithWinningTickets.filter(
        (winningTicketData: any) => winningTicketData.ticketsWithUnclaimedRewards
      );

      return roundsWithUnclaimedWinningTickets as any;
    }
    // All rounds claimed, return empty array
    return [];
  };

  const setLotteryState = (state: LotteryState) => {
    setState(prev => ({ ...prev, ...state }));
  };

  const setLotteryIsTransitioning = ({ isTransitioning }: { isTransitioning: boolean }) => {
    setState(prev => ({ ...prev, isTransitioning }));
  };

  return (
    <LotteryContext.Provider
      value={{
        ...state,
        setLotteryState,
        setLotteryIsTransitioning,
        fetchCurrentLotteryId,
        fetchPublicLotteries,
        fetchCurrentLottery,
        fetchUserTicketsAndLotteries,
        fetchUserLotteries,
        fetchUnclaimedUserRewards,
        fetchLottery,
        fetchUserTicketsForOneRound,
        getWinningTickets
      }}
    >
      {props.children}
    </LotteryContext.Provider>
  );
};
