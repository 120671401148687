import { DefaultReadonlyProvider, getToken, networkIds } from "config/networks";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { ONE_ETHER, ZERO } from "utils/number";
import axios from "axios";
import { parseEther } from "ethers/lib/utils";
import { ERC20Service } from "services/erc20";

interface IState {
  price: BigNumber;
}

export const usePricePlay = () => {
  const [state, setState] = useState<IState>({ price: ZERO });

  const bnbb = getToken("bnbb", networkIds.BSC);
  const usdt = getToken("usdt", networkIds.BSC);
  const bnbbUsdt = getToken("bnbb-usdt", networkIds.BSC);

  useEffect(() => {
    let isMounted = true;

    const loadPlayPrice = async () => {
      try {
        const bnbbToken = new ERC20Service(DefaultReadonlyProvider, "", bnbb.address);
        const usdtToken = new ERC20Service(DefaultReadonlyProvider, "", usdt.address);

        const [bnbbBalance, usdtBalance] = await Promise.all([
          bnbbToken.getBalanceOf(bnbbUsdt.address),
          usdtToken.getBalanceOf(bnbbUsdt.address)
        ]);

        setState(prev => ({
          ...prev,
          price: usdtBalance.mul(ONE_ETHER).div(bnbbBalance)
        }));
      } catch (error) {
        console.error(error);
        setState(prev => ({ ...prev, price: ZERO }));
      }
    };

    loadPlayPrice();

    let interval = setInterval(loadPlayPrice, 60000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  return state.price;
};
