export enum ConnectorNames {
  Injected = "injected",
  TrustWallet = "trustwallet",
  WalletConnect = "walletconnect",
  TokenPocket = "tockenpocket"
}

export enum ETHEME {
  White = "white",
  Black = "black"
}

export enum LotteryStatus {
  PENDING = "pending",
  OPEN = "open",
  CLOSE = "close",
  CLAIMABLE = "claimable"
}

export enum FetchStatus {
  NOT_FETCHED = "not-fetched",
  SUCCESS = "success",
  FAILED = "failed"
}

export enum IDOStatus {
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Claimable = "Claimable"
}

export enum IDORound {
  None = "None",
  Platinum = "Platinum Tier Round",
  Gold = "Gold Tier Round",
  Silver = "Silver Tier Round",
  Bronze = "Bronze Tier Round",
  Whitelist = "Whitelist Round",
  FCFS = "FCFS Round"
}

export enum IDOSection {
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended"
}

export enum IDOVersion {
  v1 = "v1",
  v2 = "v2",
  v3 = "v3",
  v4 = "v4",
  sec = "sec"
}

export enum LeagueSection {
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Finalized = "Finalized"
}
