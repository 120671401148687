import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { SVG_ICONS } from "config/constants";
import React from "react";
import { IMetadata } from "types";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import WebsiteIcon from "@material-ui/icons/Language";
import { transparentize } from "polished";

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -6,
    marginRight: -6
  },
  link: {
    backgroundColor: transparentize(0.9, theme.colors.primary),
    display: "inline-flex",
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",

    margin: "0 6px",
    marginBottom: 12,
    "&:hover": {
      backgroundColor: transparentize(0.8, theme.colors.primary)
    },
    "& svg": {
      color: theme.colors.primary,
      width: 20,
      height: 20
    }
  }
}));

interface IProps {
  data: IMetadata;
  className?: string;
}

export const IDOMetaDataRow = (props: IProps) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={clsx(classes.root, props.className)}>
      {data.website && (
        <a href={data.website} className={classes.link} target="_blank" rel="noreferrer">
          <WebsiteIcon />
        </a>
      )}
      {data.twitter && (
        <a href={data.twitter} className={classes.link} target="_blank" rel="noreferrer">
          <TwitterIcon />
        </a>
      )}
      {data.telegram && (
        <a href={data.telegram} className={classes.link} target="_blank" rel="noreferrer">
          <TelegramIcon />
        </a>
      )}
      {data.discord && (
        <a href={data.discord} className={classes.link} target="_blank" rel="noreferrer">
          <SVG_ICONS.discordHandle />
        </a>
      )}
    </div>
  );
};
