import { colors, createMuiTheme } from "@material-ui/core";
import _ from "lodash";
import { ETHEME } from "utils/enums";

import CustomColors from "./colors";
import custom from "./custom";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  typography: {
    fontFamily: [
      "Ubuntu",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h1: {
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundImage: "linear-gradient(to top right,#f8dc2a, #FFEC73)",
      width: "100%",
      fontSize: "3rem",
      lineHeight: "1.4",
      fontWeight: 800,
      marginTop: 24,
      marginBottom: 16
    }
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden"
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)"
      }
    }
  }
};

const themesOptions = [
  {
    name: ETHEME.Black,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#6F767E"
          }
        }
      },
      MuiButton: {
        root: {
          borderRadius: "4px",
          textTransform: "none"
        },
        containedPrimary: {
          backgroundColor: "#f8dc2a",
          color: "#000",
          "&:hover": {
            backgroundColor: "#f8dc2a"
          }
        },
        outlinedPrimary: {
          backgroundColor: "transparent",
          color: "red",
          "&:hover": {
            backgroundColor: "#f8dc2a",
            color: "#000"
          }
        },
        textPrimary: {
          backgroundColor: "transparent",
          color: "#f8dc2a",
          textDecoration: "none",
          padding: "8px 12px",
          "&:hover": {
            textDecoration: "primary"
          }
        },
        label: {
          fontWeight: 400
        }
      }
    },
    palette: {
      type: "dark",
      action: {
        active: "#21212188",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",

        focus: "rgba(255, 255, 255, 0.12)"
      },
      background: {
        default: "#030616",
        dark: "#000",
        paper: "#282C34"
      },
      primary: {
        main: "#FFF"
      },
      secondary: {
        main: "#7B8A95"
      },
      text: {
        primary: "#fff",
        secondary: "#fff"
      }
    },
    shadows: strongShadows
  },
  {
    name: ETHEME.White,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#6F767E"
          }
        }
      },
      MuiButton: {
        root: {
          borderRadius: "4px",
          textTransform: "none"
        },
        containedPrimary: {
          backgroundColor: "#f8dc2a",
          color: "#000",
          "&:hover": {
            backgroundColor: "#f8dc2a"
          }
        },
        outlinedPrimary: {
          backgroundColor: "transparent",
          color: "#f8dc2a",
          padding: "8px 12px",
          "&:hover": {
            backgroundColor: "#f8dc2a",
            color: "#000"
          }
        },
        textPrimary: {
          backgroundColor: "transparent",
          color: "#f8dc2a",
          textDecoration: "none",
          padding: "8px 12px",
          "&:hover": {
            textDecoration: "underline"
          }
        },
        label: {
          fontWeight: 400
        }
      }
    },
    palette: {
      type: "light",
      action: {
        active: "#21212188",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        focus: "rgba(255, 255, 255, 0.12)"
      },
      background: {
        default: "#1d1d20",
        dark: "#212121",
        paper: colors.common.white
      },
      primary: {
        main: "#212121"
      },
      secondary: {
        main: "#7B8A95"
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: "#6F767E"
      }
    },
    shadows: softShadows
  }
];

export const createTheme = (themeId: ETHEME) => {
  let themeOptions = themesOptions.find(theme => theme.name === themeId);
  let customColor = CustomColors.find(element => element.name === themeId);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${themeId} is not valid`));
    [themeOptions] = themesOptions;
  }
  if (!customColor) {
    console.warn(new Error(`The theme ${themeId} is not valid`));
    [customColor] = CustomColors;
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions, { custom }, customColor) as any);

  return theme;
};
