import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: { textAlign: "center", padding: 16 },
  loader: {
    color: theme.colors.neutralLighter
  }
}));

export const SimpleLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
    </div>
  );
};
