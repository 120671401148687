import { useEffect, useState } from "react";

interface IState {
  diff: number;
  day: number;
  hour: number;
  min: number;
  sec: number;
}

export const useCountDownTimer = (destTimeStamp: number) => {
  const [state, setState] = useState<IState>({
    diff: 0,
    day: 0,
    hour: 0,
    min: 0,
    sec: 0
  });

  useEffect(() => {
    const updateData = () => {
      const timestamp = Date.now();

      if (timestamp > destTimeStamp) {
        setState(() => ({
          diff: 0,
          day: 0,
          hour: 0,
          min: 0,
          sec: 0
        }));
        clearInterval(interval);

        interval = null as any;
      } else {
        const diff = destTimeStamp - timestamp;
        const secs = Math.floor(diff / 1000);
        const sec = secs % 60;
        const mins = Math.floor(secs / 60);
        const min = mins % 60;
        const hours = Math.floor(mins / 60);
        const hour = hours % 24;
        const day = Math.floor(hours / 24);

        setState(() => ({
          diff,
          day,
          hour,
          min,
          sec
        }));
      }
    };

    let interval = setInterval(updateData, 1000);
    updateData();
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [destTimeStamp]);

  return state;
};
