import { makeStyles } from "@material-ui/core";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  expand: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    color: theme.colors.primary,
    fontWeight: 600,
    "& svg": {
      transition: "all 0.4s"
    },
    "&.expanded": {
      "& svg": {
        transform: "rotate(180deg)"
      }
    }
  }
}));

interface IProps {
  expanded: boolean;
  onClick: () => void;
}

export const ExpandableLabel: React.FC<IProps> = props => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.expand, props.expanded ? "expanded" : "")} onClick={props.onClick}>
      {props.children}&nbsp;
      <ExpandMoreOutlinedIcon />
    </span>
  );
};
