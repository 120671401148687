import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { DEFAULT_NETWORK_ID } from "../config/constants";
import { supportedNetworkIds, supportedNetworkURLs } from "../config/networks";

const POLLING_INTERVAL = 12000;

const injected = new InjectedConnector({
  supportedChainIds: supportedNetworkIds
});

const walletconnect = new WalletConnectConnector({
  rpc: {
    [supportedNetworkIds[0]]: supportedNetworkURLs[supportedNetworkIds[0]],
    [supportedNetworkIds[2]]: supportedNetworkURLs[supportedNetworkIds[2]],
    [supportedNetworkIds[3]]: supportedNetworkURLs[supportedNetworkIds[3]]
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});

export default {
  injected,
  trustwallet: injected,
  walletconnect,
  tockenpocket: injected
};
