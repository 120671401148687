import { makeStyles } from "@material-ui/core";
import { transparentize } from "polished";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "32px 0",
    "& + &": {
      borderTop: `1px solid ${transparentize(0.7, theme.colors.third)}`
    }
  }
}));

export const PageSection: React.FC = props => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};
