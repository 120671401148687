import React from "react";
import { transparentize } from "polished";
import { Box, makeStyles } from "@material-ui/core";
import { SVG_ICONS } from "config/constants";
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 60,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  main: {
    padding: "12px 0",
    borderTop: `1px solid ${transparentize(0.8, theme.colors.neutralLighter)}`,
    position: "relative"
  },
  illustration: {
    pointerEvents: "none",
    zIndex: -1,
    overflow: "hidden",
    "& svg": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      transform: "translateX(-50%)",
      opacity: 0.4,
      marginLeft: "6rem"
    }
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  socialWrapper: {
    display: "flex",
    zIndex: 10,
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      order: 2
    }
  },
  sociaItem: {
    listStyleType: "none"
  },
  socialLink: {
    backgroundColor: transparentize(0.9, theme.colors.primary),
    display: "inline-flex",
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    margin: "0 6px",
    marginBottom: 12,
    "&:hover": {
      backgroundColor: transparentize(0.8, theme.colors.primary)
    },
    "& svg": {
      color: theme.colors.primary,
      width: 20,
      height: 20
    }
  },
  copyright: {
    color: transparentize(0.5, theme.colors.neutralLighter)
  }
}));
const socialItems = [
  {
    id: "twitter",
    icon: SVG_ICONS.twitter,
    href: "https://twitter.com/BNB_BALL"
  },
  // {
  //   id: "discord",
  //   icon: SVG_ICONS.discord,
  //   href: "https://discord.gg/qNgHb92w4p",
  // },
  // {
  //   id: "medium",
  //   icon: SVG_ICONS.medium,
  //   href: "https://metaplay.medium.com/",
  // },
  {
    id: "telegram",
    icon: SVG_ICONS.telegram,
    href: "https://t.me/bnbballofficial"
  }
  // {
  //   id: "instagram",
  //   icon: SVG_ICONS.instagram,
  //   href: "https://www.instagram.com/metaplay_games/",
  // },
  // {
  //   id: "youtube",
  //   icon: SVG_ICONS.youtube,
  //   href: "https://www.youtube.com/channel/UCEUKRXmSm0T4g52XmnPiQLQ",
  // },
];

export const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Box className={classes.main}>
        {/* <div className={classes.illustration}>
          <svg
            width="800"
            height="264"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="400"
              cy="400"
              r="400"
              fill="url(#footerglow_paint0_radial)"
              fillOpacity=".4"
            />
            <defs>
              <radialGradient
                id="footerglow_paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(90 0 400) scale(315.089)"
              >
                <stop stopColor="#f8dc2a" />
                <stop offset="1" stopColor="#f8dc2a" stopOpacity=".01" />
              </radialGradient>
            </defs>
          </svg>
        </div> */}
        <div className={classes.content}>
          <ul className={classes.socialWrapper}>
            {socialItems.map(item => {
              const Icon = item.icon;
              return (
                <li className={classes.sociaItem} key={item.id}>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      window.open(item.href, "_blank");
                      return false;
                    }}
                    className={classes.socialLink}
                  >
                    <Icon />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className={classes.copyright}>&copy; 2024 BNBBall. All rights reserved.</div>
        </div>
      </Box>
    </footer>
  );
};
