import { IDOS_TO_HIDE } from "config/constants";
import { getGraphInfo, networkIds } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { useEffect, useState } from "react";
import { IIDO } from "types";
import { IDOSection } from "utils/enums";
import { getCurrentTimeStamp } from "utils/ido";
import { fetchQuery } from "utils/thegraph";

const query = (mode: IDOSection) => {
  switch (mode) {
    case IDOSection.Upcoming:
      return `
  query ($banIds: [ID!], $first: Int!, $skip: Int!, $timestamp: String!) {
    idos(first: $first, skip: $skip, orderBy: startTime, orderDirection: asc, where: {id_not_in:$banIds, startTime_gte: $timestamp}) {
      id
      address
    }
  }
`;
    case IDOSection.Ongoing:
      return `
  query ($banIds: [ID!], $first: Int!, $skip: Int!, $timestamp: String!) {
    idos(first: $first, skip: $skip, orderBy: startTime, orderDirection: desc, where: {id_not_in:$banIds, endTime_gte: $timestamp, startTime_lte: $timestamp}) {
      id
      address
    }
  }
`;
    default:
      return `
  query ($banIds: [ID!], $first: Int!, $skip: Int!, $timestamp: String!) {
    idos(first: $first, skip: $skip, orderBy: endTime, orderDirection: desc, where: {id_not_in:$banIds, endTime_lte: $timestamp}) {
      id
      address
    }
  }
`;
  }
};

const wrangeIDO = (ido: any) =>
  ({
    ...ido
  } as IIDO);

interface IState {
  loading: boolean;
  data: IIDO[];
  hasMore: boolean;
}

const PerPage = 6;

export const useIDOs = (mode: IDOSection) => {
  const { networkId } = useConnectedWeb3Context();
  const idoGraphHttpEndPoint = getGraphInfo("ido", networkId).httpUri;
  const [state, setState] = useState<IState>({
    loading: true,
    data: [],
    hasMore: false
  });

  const loadIDOs = async (skip: number) => {
    if (networkId === networkIds.BSCTEST) {
      setState(prev => ({ ...prev, loading: false }));
      return;
    }
    setState(prev => ({ ...prev, loading: true }));
    try {
      const currentTimeStamp = getCurrentTimeStamp();
      const result = (
        await fetchQuery(
          query(mode),
          {
            banIds: IDOS_TO_HIDE,
            skip,
            first: PerPage + 1,
            timestamp: currentTimeStamp.toString()
          },
          idoGraphHttpEndPoint
        )
      ).data;
      if (result.data && result.data.idos) {
        setState(prev => ({
          ...prev,
          loading: false,
          data:
            skip === 0
              ? result.data.idos.map((ido: any) => wrangeIDO(ido)).slice(0, PerPage)
              : [...state.data, ...result.data.idos.map((ido: any) => wrangeIDO(ido)).slice(0, PerPage)],
          hasMore: result.data.idos.length > PerPage
        }));
      } else {
        setState(prev => ({ ...prev, loading: false, data: [] }));
      }
    } catch (error) {
      setState(prev => ({ ...prev, loading: false, data: [] }));
    }
  };

  const loadMore = async () => {
    await loadIDOs(state.data.length);
  };

  useEffect(() => {
    setState(prev => ({ ...prev, data: [], hasMore: false }));
    loadIDOs(0);
  }, [networkId]);

  return { ...state, load: loadIDOs, loadMore };
};
