import { makeStyles } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    color: theme.colors.primary,
    marginTop: 8,
    marginBottom: 8
  }
}));

interface IProps {
  label: string;
  link: string;
  className?: string;
}

export const InfoLinkRow = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <a href={props.link} rel="noreferrer" target="_blank" className={classes.content}>
        {props.label}
        &nbsp;
        <LaunchIcon style={{ fontSize: 16 }} />
      </a>
    </div>
  );
};
