import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useConnectedWeb3Context } from "contexts";

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.colors.primary,
    // color: theme.colors.black,
    height: 40,

    "& span": { fontWeight: 600 }
  }
}));

interface IProps {
  className?: string;
}

export const ConnectWalletButton = (props: IProps) => {
  const classes = useStyles();
  const { setWalletConnectModalOpened } = useConnectedWeb3Context();
  return (
    <>
      <Button
        className={clsx(classes.root, props.className)}
        color="primary"
        variant="contained"
        onClick={() =>
          window.open(
            "https://pancakeswap.finance/swap?outputCurrency=0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
            "_blank"
          )
        }
      >
        BUY TOKEN
      </Button>
      <Button
        className={clsx(classes.root, props.className)}
        color="primary"
        onClick={() => setWalletConnectModalOpened(true)}
        variant="contained"
      >
        Connect
      </Button>
    </>
  );
};
