import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { transparentize } from "polished";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {},
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.colors.neutralDarker,
    height: 30,
    borderRadius: 16,
    "& span": {
      color: theme.colors.neutralLighter,
      zIndex: 1
    },
    "&.thick": {
      height: 40,
      borderRadius: "24px"
    }
  },
  progress: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.colors.primary
  }
}));

interface IProps {
  label: string;
  percent: number;
  thick?: boolean;
  className?: string;
}

export const ProgressBar = (props: IProps) => {
  const classes = useStyles();
  const { label, percent } = props;
  const thick = props.thick || false;

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={clsx(classes.progressWrapper, thick && "thick")}>
        <span>{label}</span>
        <div className={classes.progress} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};
