import { CircularProgress, makeStyles } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  img: {},
  loader: {
    color: theme.colors.neutralLighter
  }
}));

interface IProps {
  className?: string;
  imgClassName?: string;
  imgStyle?: any;
  src: string;
  onImgLoaded?: (width: number, height: number) => void;
}

export const LazyImage = (props: IProps) => {
  const classes = useStyles();
  const [imgData, setImgData] = useState("");

  useEffect(() => {
    let isMounted = true;
    const loadInfo = async () => {
      try {
        const info = (await axios.get(props.src)).data;
        setImgData(info);
      } catch (error) {
        console.warn(error);
      }
    };
    loadInfo();
    return () => {
      isMounted = false;
    };
  }, [props.src]);

  const onImgLoad = ({ target: img }: any) => {
    props.onImgLoaded && props.onImgLoaded(img.offsetWidth, img.offsetHeight);
  };

  return (
    <div className={clsx(classes.root, props.className)}>
      {imgData ? (
        <img
          alt="alt"
          src={imgData}
          className={clsx(classes.img, props.imgClassName)}
          onLoad={onImgLoad}
          style={props.imgStyle || {}}
        />
      ) : (
        <CircularProgress className={classes.loader} size={20} />
      )}
    </div>
  );
};
