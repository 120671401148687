import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { BigNumber, ethers } from "ethers";
import { transparentize } from "polished";
import { useEffect, useState } from "react";
import useCommonStyles from "style/common";
import { IToken } from "types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "12px 16px",

    border: `1px solid ${transparentize(0.6, theme.colors.neutralLighter)}`,
    marginBottom: 8
  },
  input: {
    color: theme.colors.neutralLighter,
    "& input": { textAlign: "right" }
  },
  max: {
    fontWeight: 600,
    backgroundColor: theme.colors.primary,
    color: theme.colors.black,
    padding: "4px 8px",
    borderRadius: 8,
    cursor: "pointer",
    transition: "all 0.4s",
    "&:hover": {
      opacity: 0.7
    }
  },
  unit: {
    color: theme.colors.primary
  }
}));

interface IProps {
  className?: string;
  value: BigNumber;
  onChange: (value: BigNumber) => void;
  token: IToken;
  onMax: () => void;
}

interface IState {
  amount: string;
}

export const BigNumberInput = (props: IProps) => {
  const { token, onChange, onMax } = props;
  const [state, setState] = useState<IState>({ amount: "0" });
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  useEffect(() => {
    if (!ethers.utils.parseUnits(state.amount || "0", token.decimals).eq(props.value)) {
      setState(prev => ({
        ...prev,
        amount: ethers.utils.formatUnits(props.value || "0", token.decimals)
      }));
    }
  }, [props.value, state.amount, token.decimals]);

  return (
    <div className={clsx(classes.root, props.className)}>
      <Input
        className={classes.input}
        classes={{ input: commonClasses.hideInputArrow }}
        disableUnderline
        fullWidth
        value={state.amount}
        onChange={e => {
          if (Number(e.target.value) < 0) return;
          setState(prev => ({ ...prev, amount: e.target.value }));
          onChange(ethers.utils.parseUnits(e.target.value || "0", token.decimals));
        }}
        type="number"
        endAdornment={
          <InputAdornment position="end">
            <>
              <span className={classes.unit}>{token.symbol.toUpperCase()}</span>
              &nbsp;
              <span className={classes.max} onClick={onMax}>
                MAX
              </span>
            </>
          </InputAdornment>
        }
      />
    </div>
  );
};
