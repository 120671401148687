import { BigNumber, Contract, Wallet, ethers } from "ethers";
import { Maybe } from "types";
import tierAbi from "abis/tier.json";

class TierService {
  provider: any;
  contract: Contract;

  constructor(provider: any, signerAddress: Maybe<string>, addr: string) {
    this.provider = provider;
    if (signerAddress) {
      const signer: Wallet = provider.getSigner();
      this.contract = new ethers.Contract(addr, tierAbi, provider).connect(signer);
    } else {
      this.contract = new ethers.Contract(addr, tierAbi, provider);
    }
  }

  get address(): string {
    return this.contract.address;
  }
  async getTierId(address: string): Promise<number> {
    const res = await this.contract.getTierId(address);
    return res.toNumber();
  }
}

export { TierService };
