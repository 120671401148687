import axios from "axios";
import { DefaultReadonlyProvider, getToken, getTokenFromAddress, networkIds } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { ERC20Service } from "services/erc20";
import { ONE_ETHER, ZERO } from "utils/number";

interface IState {
  token0: BigNumber;
  token1: BigNumber;
}

export const useTokenPrice = (address: string) => {
  const [state, setState] = useState<IState>({
    token0: ZERO,
    token1: ZERO
  });
  const { library: provider, networkId } = useConnectedWeb3Context();

  useEffect(() => {
    const calculatePrice = async () => {
      const token = getTokenFromAddress(address, networkIds.BSC);

      // const finalLPTokenSymbol = (() => {
      //   switch (token.symbol.toLowerCase()) {
      //     case "bnbb":
      //       return "bnbb-usdt";
      //     case "usdt":
      //       return "bnbb-usdt";
      //     default:
      //       return "bnbb-usdt";
      //   }
      // })();

      const lpToken = getToken("bnbb-usdt", networkIds.BSC);
      // const lpToken = getToken(finalLPTokenSymbol, networkIds.BSC);
      const usdtToken = getToken("usdt", networkIds.BSC);
      const usdt = new ERC20Service(DefaultReadonlyProvider, "", usdtToken.address);
      const token0 = new ERC20Service(DefaultReadonlyProvider, "", lpToken.token0 || "");
      try {
        const [usdtBalance, token0Balance] = await Promise.all([
          usdt.getBalanceOf(lpToken.address),
          token0.getBalanceOf(lpToken.address)
        ]);

        const token0Price = usdtBalance.mul(ONE_ETHER).div(token0Balance);
        setState(() => ({
          token0: token0Price,
          token1: ONE_ETHER
        }));
      } catch (error) {
        setState(() => ({
          token0: ZERO,
          token1: ZERO
        }));
      }
    };

    calculatePrice();

    let interval = setInterval(calculatePrice, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [address]);

  return state;
};
