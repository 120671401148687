import { makeStyles } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: "cover",
    backgroundPosition: "left"
  }
}));

interface IProps {
  className?: string;
  src: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const LazyBg = (props: IProps) => {
  const classes = useStyles();
  const [imgData, setImgData] = useState("");

  const showMock = !props.src || imgData === "";

  useEffect(() => {
    let isMounted = true;
    const loadInfo = async () => {
      try {
        const info = (await axios.get(props.src)).data;
        setImgData(info);
      } catch (error) {
        console.warn(error);
      }
    };
    if (!props.src) {
      loadInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [props.src]);

  return (
    <div
      className={clsx(classes.root, props.className)}
      style={{
        backgroundImage: showMock ? "url(/assets/bg-2.png)" : imgData
      }}
    >
      {props.children}
    </div>
  );
};
