import { makeStyles } from "@material-ui/core";
import React from "react";

import { Header, Footer } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.colors.neutral,
    transition: "all 0.2s"
  },
  content: {
    paddingTop: theme.custom.appHeaderHeight,
    backgroundPositionX: "left",
    backgroundPositionY: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }
}));

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const MainLayout = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>{props.children}</main>
      <Footer />
    </div>
  );
};
