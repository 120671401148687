import { LoadingScreen } from "components";
import { MainLayout } from "layouts";
import React, { Fragment, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const routes = [
  {
    path: "/",
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("pages/HomePage"))
      },
      {
        exact: true,
        path: "/calendar",
        component: lazy(() => import("pages/CalendarPage"))
      },
      {
        exact: true,
        path: "/learn-more",
        component: lazy(() => import("pages/LearnMorePage"))
      },
      {
        exact: true,
        path: "/lottery",
        component: lazy(() => import("pages/LotteryPage"))
      },
      {
        exact: true,
        path: "/staking",
        component: lazy(() => import("pages/StakingPage"))
      },
      {
        exact: true,
        path: "/entry-lock",
        component: lazy(() => import("pages/EntryLockPage"))
      },
      {
        exact: true,
        path: "/tournament",
        component: lazy(() => import("pages/TournamentPage"))
      },
      {
        exact: true,
        path: "/play-pad",
        component: lazy(() => import("pages/IDOPage"))
      },
      {
        exact: true,
        path: "/play-pad/:id",
        component: lazy(() => import("pages/IDODetailsPage"))
      },
      {
        exact: true,
        path: "/sports-pools",
        component: lazy(() => import("pages/LeaguePage"))
      },
      {
        exact: true,
        path: "/sports-pools/:id",
        component: lazy(() => import("pages/LeagueDetailsPage"))
      },
      {
        exact: true,
        path: "/faq",
        component: lazy(() => import("pages/FaqPage"))
      },
      {
        exact: true,
        path: "/support",
        component: lazy(() => import("pages/SupportPage"))
      },
      {
        exact: true,
        path: "/ido-claims",
        component: lazy(() => import("pages/IDOClaimPage"))
      },
      {
        exact: true,
        path: "/link-sol",
        component: lazy(() => import("pages/SolLinkPage"))
      },
      {
        exact: true,
        path: "/multisig",
        component: lazy(() => import("pages/Multisig"))
      },
      {
        path: "*",
        // eslint-disable-next-line
        component: () => <Redirect to="/" />
      }
    ]
  }
];

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: any, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            exact={route.exact}
            key={i}
            path={route.path}
            render={props => <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default routes;
