import { BigNumber } from "ethers";
import {
  LotteryResponse,
  LotteryRound,
  LotteryRoundGraphEntity,
  LotteryRoundUserTickets,
  LotteryTicket,
  UserRound,
  UserTicketsResponse
} from "types";
import { LotteryStatus } from "utils/enums";

/**
 * Remove the '1' and reverse the digits in a lottery number retreived from the smart contract
 */
export const parseRetreivedNumber = (number: string): string => {
  const numberAsArray = number.split("");
  numberAsArray.splice(0, 1);
  numberAsArray.reverse();
  return numberAsArray.join("");
};

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric"
};

export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric"
};

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
  ...dateOptions,
  ...timeOptions
};

export const getDrawnDate = (endTime: string) => {
  const endTimeInMs = parseInt(endTime, 10) * 1000;
  const endTimeAsDate = new Date(endTimeInMs);
  return endTimeAsDate.toLocaleDateString(undefined, dateTimeOptions);
};

export const processLotteryResponse = (
  lotteryData: LotteryResponse & { userTickets?: LotteryRoundUserTickets }
): LotteryRound => {
  const {
    priceTicketInPlay: priceTicketInPlayAsString,
    discountDivisor: discountDivisorAsString,
    amountCollectedInPlay: amountCollectedInPlayAsString
  } = lotteryData;

  const discountDivisor = BigNumber.from(discountDivisorAsString);
  const priceTicketInPlay = BigNumber.from(priceTicketInPlayAsString);
  const amountCollectedInPlay = BigNumber.from(amountCollectedInPlayAsString);

  return {
    isLoading: lotteryData.isLoading,
    lotteryId: lotteryData.lotteryId,
    userTickets: lotteryData.userTickets,
    status: lotteryData.status,
    startTime: lotteryData.startTime,
    endTime: lotteryData.endTime,
    priceTicketInPlay,
    discountDivisor,
    treasuryFee: lotteryData.treasuryFee,
    firstTicketId: lotteryData.firstTicketId,
    lastTicketId: lotteryData.lastTicketId,
    amountCollectedInPlay,
    finalNumber: lotteryData.finalNumber,
    playPerBracket: lotteryData.playPerBracket,
    countWinnersPerBracket: lotteryData.countWinnersPerBracket,
    rewardsBreakdown: lotteryData.rewardsBreakdown
  };
};

export const processViewLotterySuccessResponse = (response: any, lotteryId: string): LotteryResponse => {
  const {
    status,
    startTime,
    endTime,
    priceTicketInPlay,
    discountDivisor,
    treasuryFee,
    firstTicketId,
    lastTicketId,
    amountCollectedInPlay,
    finalNumber,
    playPerBracket,
    countWinnersPerBracket,
    rewardsBreakdown
  } = response;

  const statusKey = Object.keys(LotteryStatus)[status];
  const serializedplayPerBracket = playPerBracket.map((playInBracket: BigNumber) => playInBracket.toString());
  const serializedCountWinnersPerBracket = countWinnersPerBracket.map((winnersInBracket: BigNumber) =>
    winnersInBracket.toString()
  );
  const serializedRewardsBreakdown = rewardsBreakdown.map((reward: BigNumber) => reward.toString());

  return {
    isLoading: false,
    lotteryId,
    status: (LotteryStatus as any)[statusKey],
    startTime: startTime?.toString(),
    endTime: endTime?.toString(),
    priceTicketInPlay: priceTicketInPlay.toString(),
    discountDivisor: discountDivisor?.toString(),
    treasuryFee: treasuryFee?.toString(),
    firstTicketId: firstTicketId?.toString(),
    lastTicketId: lastTicketId?.toString(),
    amountCollectedInPlay: amountCollectedInPlay.toString(),
    finalNumber,
    playPerBracket: serializedplayPerBracket,
    countWinnersPerBracket: serializedCountWinnersPerBracket,
    rewardsBreakdown: serializedRewardsBreakdown
  };
};

export const processViewLotteryErrorResponse = (lotteryId: string): LotteryResponse => {
  return {
    isLoading: true,
    lotteryId,
    status: LotteryStatus.PENDING,
    startTime: "",
    endTime: "",
    priceTicketInPlay: "",
    discountDivisor: "",
    treasuryFee: "",
    firstTicketId: "",
    lastTicketId: "",
    amountCollectedInPlay: "",
    finalNumber: null,
    playPerBracket: [],
    countWinnersPerBracket: [],
    rewardsBreakdown: []
  };
};

export const processRawTicketsResponse = (ticketsResponse: UserTicketsResponse): LotteryTicket[] => {
  const [ticketIds, ticketNumbers, ticketStatuses] = ticketsResponse;

  if (ticketIds?.length > 0) {
    return ticketIds.map((ticketId, index) => {
      return {
        id: ticketId.toString(),
        number: ticketNumbers[index].toString(),
        status: ticketStatuses[index]
      };
    });
  }
  return [];
};

export const applyNodeDataToLotteriesGraphResponse = (
  nodeData: LotteryResponse[],
  graphResponse: LotteryRoundGraphEntity[]
): LotteryRoundGraphEntity[] => {
  //   If no graph response - return node data
  if (graphResponse.length === 0) {
    return nodeData.map(nodeRound => {
      return {
        endTime: nodeRound.endTime,
        finalNumber: nodeRound.finalNumber?.toString() || "",
        startTime: nodeRound.startTime,
        status: nodeRound.status,
        id: nodeRound.lotteryId?.toString() || "",
        ticketPrice: nodeRound.priceTicketInPlay,
        totalTickets: "",
        totalUsers: "",
        winningTickets: ""
      };
    });
  }

  //   Else if there is a graph response - merge with node data where node data is more reliable
  const mergedResponse = graphResponse.map((graphRound, index) => {
    const nodeRound = nodeData[index];
    // if there is node data for this index, overwrite graph data. Otherwise - return graph data.
    if (nodeRound) {
      // if isLoading === true, there has been a node error - return graphRound
      if (!nodeRound.isLoading) {
        return {
          endTime: nodeRound.endTime,
          finalNumber: nodeRound.finalNumber?.toString() || "",
          startTime: nodeRound.startTime,
          status: nodeRound.status,
          id: graphRound.id,
          ticketPrice: graphRound.ticketPrice,
          totalTickets: graphRound.totalTickets,
          totalUsers: graphRound.totalUsers,
          winningTickets: graphRound.winningTickets
        };
      }
      return graphRound;
    }
    return graphRound;
  });
  return mergedResponse;
};

export const hasRoundBeenClaimed = (tickets: LotteryTicket[]): boolean => {
  const claimedTickets = tickets.filter(ticket => ticket.status);
  return claimedTickets.length > 0;
};

export const applyNodeDataToUserGraphResponse = (
  userNodeData: { roundId: string; userTickets: LotteryTicket[] }[],
  userGraphData: UserRound[],
  lotteryNodeData: LotteryResponse[]
): UserRound[] => {
  //   If no graph rounds response - return node data
  if (userGraphData.length === 0) {
    return lotteryNodeData.map(nodeRound => {
      const ticketDataForRound = userNodeData.find(roundTickets => roundTickets.roundId === nodeRound.lotteryId);
      return {
        endTime: nodeRound.endTime,
        status: nodeRound.status,
        lotteryId: nodeRound.lotteryId?.toString() || "",
        claimed: hasRoundBeenClaimed(ticketDataForRound?.userTickets || []),
        totalTickets: `${ticketDataForRound?.userTickets.length.toString()}`,
        tickets: ticketDataForRound?.userTickets
      };
    });
  }

  //   Else if there is a graph response - merge with node data where node data is more accurate
  const mergedResponse = userGraphData.map((graphRound, index) => {
    const nodeRound = lotteryNodeData[index];
    // if there is node data for this index, overwrite graph data. Otherwise - return graph data.
    if (nodeRound) {
      const ticketDataForRound = userNodeData.find(roundTickets => roundTickets.roundId === nodeRound.lotteryId);
      // if isLoading === true, there has been a node error - return graphRound
      if (!nodeRound.isLoading) {
        return {
          endTime: nodeRound.endTime,
          status: nodeRound.status,
          lotteryId: nodeRound.lotteryId?.toString() || "",
          claimed: hasRoundBeenClaimed(ticketDataForRound?.userTickets || []),
          totalTickets: graphRound.totalTickets,
          tickets: ticketDataForRound?.userTickets
        };
      }
      return graphRound;
    }
    return graphRound;
  });
  return mergedResponse;
};

export const getRewardBracketByNumber = (ticketNumber: string, finalNumber: string): number => {
  // Winning numbers are evaluated right-to-left in the smart contract, so we reverse their order for validation here:
  // i.e. '1123456' should be evaluated as '6543211'
  const ticketNumAsArray = ticketNumber.split("").reverse();
  const winningNumsAsArray = finalNumber.split("").reverse();
  const matchingNumbers = [];

  // The number at index 6 in all tickets is 1 and will always match, so finish at index 5
  for (let index = 0; index < winningNumsAsArray.length - 1; index++) {
    if (ticketNumAsArray[index] !== winningNumsAsArray[index]) {
      break;
    }
    matchingNumbers.push(ticketNumAsArray[index]);
  }

  // Reward brackets refer to indexes, 0 = 1 match, 5 = 6 matches. Deduct 1 from matchingNumbers' length to get the reward bracket
  const rewardBracket = matchingNumbers.length - 1;
  return rewardBracket;
};
