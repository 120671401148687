import { IIDODetails, ILeagueDetails } from "types";
import { IDOStatus, LeagueSection } from "./enums";

export const getCurrentTimeStamp = () => Math.floor(Date.now() / 1000);

export const getTimeStr = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const mins = Math.floor(seconds / 60);
  if (mins < 60) {
    return `${mins}m`;
  }
  const hours = Math.floor(mins / 60);
  if (hours < 24) {
    return `${hours}h`;
  }
  const days = Math.floor(hours / 24);

  return `${days}d`;
};

export const getIDOStatus = (ido: IIDODetails): IDOStatus => {
  const currentTimeStamp = getCurrentTimeStamp();
  if (currentTimeStamp < ido.startTime) {
    return IDOStatus.Upcoming;
  }
  if (currentTimeStamp < ido.endTime) {
    return IDOStatus.Ongoing;
  }
  if (currentTimeStamp < ido.claimTime) {
    return IDOStatus.Ended;
  }
  return IDOStatus.Claimable;
};

export const getLeagueStatus = (league: ILeagueDetails): LeagueSection => {
  const currentTimeStamp = getCurrentTimeStamp();
  if (currentTimeStamp < league.startTime) {
    return LeagueSection.Upcoming;
  }
  if (currentTimeStamp < league.closeTime) {
    return LeagueSection.Ongoing;
  }
  if (!league.isFinalized) {
    return LeagueSection.Ended;
  }
  return LeagueSection.Finalized;
};
