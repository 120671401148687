import { Input, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { transparentize } from "polished";
import useCommonStyles from "style/common";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    backgroundColor: transparentize(0.9, theme.colors.fourth),
    padding: "8px 16px",

    boxShadow: "rgb(74 74 104 / 10%) 0px 2px 2px -1px inset",
    textAlign: "right",
    transition: "all 0.4s",
    "&.warning": {
      boxShadow: "rgb(237 75 158) 0px 0px 0px 1px, rgb(237 75 158 / 20%) 0px 0px 0px 4px"
    }
  },
  input: {
    color: theme.colors.fourth,
    "& input": {
      textAlign: "right"
    }
  },
  currencyValue: {
    fontSize: 14,
    color: transparentize(0.4, theme.colors.fourth)
  }
}));

interface IProps {
  isWarning: boolean;
  placeholder: string;
  value: string;
  onUserInput: (_: string) => void;
  currencyValue: any;
}

export const BalanceInput: React.FC<IProps> = props => {
  const { isWarning, placeholder, value, onUserInput, currencyValue } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.content, isWarning ? "warning" : "")}>
        <Input
          className={classes.input}
          classes={{ input: commonClasses.hideInputArrow }}
          value={value}
          placeholder={placeholder}
          onChange={e => onUserInput(e.target.value || "0")}
          type="number"
          disableUnderline
        />
        <Typography align="right" className={classes.currencyValue}>
          {currencyValue}
        </Typography>
      </div>
    </div>
  );
};
