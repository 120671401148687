import axios from "axios";
import { DefaultReadonlyProvider, getToken, getTokenFromAddress, networkIds } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { ERC20Service } from "services/erc20";
import { ONE_ETHER, ZERO } from "utils/number";

interface IState {
  lpPrice: BigNumber;
  token0: BigNumber;
  token1: BigNumber;
  poolUsd: BigNumber;
}

export const useLPTokenPrice = (address: string) => {
  const [state, setState] = useState<IState>({
    lpPrice: ZERO,
    token0: ZERO,
    token1: ZERO,
    poolUsd: ZERO
  });
  const { library: provider, networkId } = useConnectedWeb3Context();

  useEffect(() => {
    const calculatePrice = async () => {
      const lpToken = getTokenFromAddress(address, networkIds.BSC);
      const lp = new ERC20Service(DefaultReadonlyProvider, "", lpToken.address);
      const usdtToken = getToken("usdt", networkIds.BSC);
      const usdt = new ERC20Service(DefaultReadonlyProvider, "", usdtToken.address);
      const token0 = new ERC20Service(DefaultReadonlyProvider, "", lpToken.token0 || "");
      try {
        const [totalSupply, usdtBalance, token0Balance] = await Promise.all([
          lp.totalSupply(),
          usdt.getBalanceOf(lpToken.address),
          token0.getBalanceOf(lpToken.address)
        ]);

        const lpPrice = usdtBalance.mul(2).mul(ONE_ETHER).div(totalSupply);
        const token0Price = usdtBalance.mul(ONE_ETHER).div(token0Balance);
        setState(() => ({
          lpPrice,
          token0: token0Price,
          token1: ONE_ETHER,
          poolUsd: usdtBalance.mul(2)
        }));
      } catch (error) {
        setState(() => ({
          lpPrice: ZERO,
          token0: ZERO,
          token1: ZERO,
          poolUsd: ZERO
        }));
      }
    };

    calculatePrice();

    let interval = setInterval(calculatePrice, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [address]);

  return state;
};
