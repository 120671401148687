import { Modal, Typography, makeStyles, Grid } from "@material-ui/core";
import clsx from "clsx";
import { ETHER_DECIMAL } from "config/constants";
import { getTierFactor, getToken } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { BigNumber } from "ethers";
import { transparentize } from "polished";
import React, { useEffect, useState } from "react";
import { ERC20Service } from "services/erc20";
import { LpMiningService } from "services/lpMining";
import { PointService } from "services/point";
import { StakingService } from "services/staking";
import { TierService } from "services/tier";
import { formatBigNumber } from "utils";
import { ZERO } from "utils/number";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "90%",
    maxWidth: 500,
    backgroundColor: theme.colors.fourth,
    padding: theme.spacing(2),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxHeight: "80vh",
    userSelect: "none",
    overflowY: "auto",

    textAlign: "center",
    border: `1px solid ${transparentize(0.8, theme.colors.neutralLighter)}`
  },
  title: {
    fontSize: 20,
    color: theme.colors.default,
    marginBottom: 16
  },
  instruction: {
    marginTop: 16,
    fontSize: 14,
    color: theme.colors.default
  },
  txLink: {
    marginTop: 16,
    display: "inline-block",
    fontSize: 16,
    color: theme.colors.default
  },
  indicator: {
    color: transparentize(0.4, theme.colors.neutralLighter)
  },
  pointTableWrapper: {},
  pointTable: {
    width: "100%",
    borderCollapse: "collapse",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    "& thead": {
      "& th": {
        color: theme.colors.primary,
        padding: "8px 4px",
        fontWeight: 600
      }
    },
    "& tbody": {
      "& tr": {
        "& td": {
          color: transparentize(0.4, theme.colors.neutralLighter),
          padding: "6px 4px",
          borderTop: `1px solid ${transparentize(0.6, theme.colors.neutralLighter)}`
        },
        "&:last-child": {
          "& td": {
            color: theme.colors.primary
          }
        }
      }
    }
  },
  tiersWrapper: {
    marginTop: 16
  },
  tierItem: {
    padding: 8,

    border: `1px solid ${transparentize(0.8, theme.colors.neutralLighter)}`,
    "&.active": {
      borderColor: theme.colors.primary,
      backgroundColor: theme.colors.primary,
      "& p": {
        color: `${theme.colors.black} !important`
      }
    }
  },
  tierItemTitle: {
    color: theme.colors.primary,
    fontSize: 20
  },
  tierItemCondition: {
    color: transparentize(0.2, theme.colors.neutralLighter),
    fontSize: 14
  },
  tierItemMulti: {
    color: transparentize(0.4, theme.colors.neutralLighter),
    fontSize: 14,
    lineHeight: "1.1",
    "& span": {
      color: theme.colors.burn
    }
  },
  yourTier: {
    color: theme.colors.primary,
    marginTop: 6
  },
  tierIcon: {
    width: 80,
    height: 80
  }
}));

interface IProps {
  className?: string;
  onClose: () => void;
}

interface IState {
  tierId: number;
  point: BigNumber;

  stakedBnbbAmount: BigNumber;
  stakedBnbbLongAmount: BigNumber;
  lockedBnbbUsdtAmount: BigNumber;
}

const defaultState: IState = {
  tierId: 0,
  point: ZERO,
  stakedBnbbAmount: ZERO,
  stakedBnbbLongAmount: ZERO,
  lockedBnbbUsdtAmount: ZERO
};

export const TIERS = [
  {
    id: 4,
    name: "Platinum",
    stake: 2000,
    multiplier: 20,
    icon: "/assets/tiers/Platinum.png"
  },
  {
    id: 3,
    name: "Gold",
    stake: 1000,
    multiplier: 10,
    icon: "/assets/tiers/Gold.png"
  },
  {
    id: 2,
    name: "Silver",
    stake: 400,
    multiplier: 4,
    icon: "/assets/tiers/Silver.png"
  },

  {
    id: 1,
    name: "Bronze",
    stake: 100,
    multiplier: 1,
    icon: "/assets/tiers/Bronze.png"
  }
];

export const TierModal = (props: IProps) => {
  const classes = useStyles();
  const { onClose } = props;
  const { account, library: provider, networkId } = useConnectedWeb3Context();

  const [state, setState] = useState<IState>(defaultState);

  useEffect(() => {
    let isMounted = true;

    const loadInfo = async () => {
      if (!account || !provider) {
        setState(prev => ({ ...prev, ...defaultState }));
        return;
      }

      try {
        const tierFactor = getTierFactor(networkId);

        const tier = new TierService(provider, account, tierFactor.tier);
        const pointService = new PointService(provider, account, tierFactor.point);
        const playStaking = new StakingService(provider, account, tierFactor.staking);
        const playStakingLong = new StakingService(provider, account, tierFactor.stakingLong);
        const bnbbUsdtLocking = new LpMiningService(provider, account, tierFactor.bnbbUsdtLock);

        const [stakedBnbbAmount, stakedBnbbLongAmount, tierId, point, lockedBnbbUsdtAmount] = await Promise.all([
          playStaking.getDepositedAmount(account),
          playStakingLong.getDepositedAmount(account),
          tier.getTierId(account),
          pointService.getPoint(account),
          bnbbUsdtLocking.getDepositedAmount(account)
        ]);
        if (isMounted)
          setState(prev => ({
            ...prev,
            stakedBnbbAmount,
            stakedBnbbLongAmount,
            tierId,
            point,
            lockedBnbbUsdtAmount
          }));
      } catch (error) {
        console.error(error);
        if (isMounted) setState(prev => ({ ...prev, ...defaultState }));
      }
    };

    loadInfo();

    return () => {
      isMounted = false;
    };
  }, [account, networkId]);

  return (
    <Modal onClose={onClose} open>
      <div className={clsx(classes.root, props.className)}>
        <Typography className={classes.title}>Tier Info</Typography>
        <div className={classes.pointTableWrapper}>
          <table className={classes.pointTable}>
            <thead>
              <tr>
                <th>Balance</th>
                <th>Amount</th>
                <th>Multiplier</th>
                <th>BNBB Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Staked BNBB(30D)</td>
                <td>{formatBigNumber(state.stakedBnbbAmount, ETHER_DECIMAL)}</td>
                <td>1.5</td>
                <td>{formatBigNumber(state.stakedBnbbAmount.mul(15).div(10), ETHER_DECIMAL)}</td>
              </tr>
              <tr>
                <td>Staked BNBB(90D)</td>
                <td>{formatBigNumber(state.stakedBnbbLongAmount, ETHER_DECIMAL)}</td>
                <td>2.2</td>
                <td>{formatBigNumber(state.stakedBnbbLongAmount.mul(22).div(10), ETHER_DECIMAL)}</td>
              </tr>

              <tr>
                <td>Locked BNBB-USDT</td>
                <td>{formatBigNumber(state.lockedBnbbUsdtAmount, ETHER_DECIMAL)}</td>
                <td>4</td>
                <td>{formatBigNumber(state.lockedBnbbUsdtAmount.mul(4), ETHER_DECIMAL)}</td>
              </tr>
              <tr>
                <td>Your Point</td>
                <td></td>
                <td></td>
                <td>{formatBigNumber(state.point, ETHER_DECIMAL, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.tiersWrapper}>
          <Grid container spacing={2}>
            {TIERS.map(tier => (
              <Grid item xs={6} sm={3} key={`${tier.id}`}>
                <div className={clsx(classes.tierItem, tier.id === state.tierId ? "active" : "")}>
                  <img alt="icon" className={classes.tierIcon} src={tier.icon} />
                  <Typography align="center" className={classes.tierItemTitle}>
                    {tier.name}
                  </Typography>
                  <Typography align="center" className={classes.tierItemCondition}>
                    {tier.stake} Points
                  </Typography>
                  <Typography align="center" className={classes.tierItemMulti}>
                    <span>{tier.multiplier}x</span> multiplier allocation*
                  </Typography>
                  {tier.id === state.tierId && (
                    <Typography align="center" className={classes.yourTier}>
                      Your Tier
                    </Typography>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Modal>
  );
};
