const custom = {
  appHeaderHeight: 0,
  appNavbarWidth: 200,
  maxContentWidth: 1200,
  navbarOnWidth: 15,
  smd: 768,
  smds: 852,
  ml: 1024
};

export default custom;
