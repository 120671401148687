import { providers } from "ethers";
import { entries } from "utils/type-utils";
import {
  IKnownTokenData,
  ILPMining,
  INetwork,
  IToken,
  ITokenStaking,
  KnownContracts,
  KnownTheGraph,
  KnownToken,
  NetworkId,
  ITierFactor
} from "types/types";
import { DEFAULT_NETWORK_ID } from "./constants";
import { ZERO } from "utils/number";

export const networkIds = {
  MAINNET: 1,
  BSC: 56,
  BSCTEST: 97,
  HARMONY: 1666600000
} as const;

const networks: { [K in NetworkId]: INetwork } = {
  [networkIds.BSC]: {
    label: "Binance Smart Chain",
    url: "https://bsc-dataseed.binance.org/",
    contracts: {
      lottery: "0xD80AFCEc42966772ffD9a21c005918B13eEFDbB4",
      multicall: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B"
    },
    tier: {
      tier: "0x969Cb9D776db37fF1b825c824175a6b3d8F4384a",
      point: "0xA1c77598d806Bd4A04d60c322f56f2fa22D6764E",
      bnbbUsdt: "0x01bd616e4f8b3ab01d4406a56d13e7efabbba2d4",
      staking: "0xBE62B9A68831d6A0dbC9F99E672c25C102d4ef10",
      stakingLong: "0xb1d7b6bac301F77e17EA128085B8D36f53Fd12b3",
      bnbbUsdtLock: "0x762Bdcb38eF3Eb94D8cd6a8024554884c0e9dA67"
    },
    etherscanUri: "https://bscscan.com/",
    thegraph: {
      lottery: {
        // httpUri:
        //   'https://api.thegraph.com/subgraphs/name/polyplaygames/lottery-bsc',
        // wsUri:
        //   'wss://api.thegraph.com/subgraphs/name/polyplaygames/lottery-bsc',
        httpUri: "",
        wsUri: ""
      },
      ido: {
        // httpUri:
        //   "https://api.thegraph.com/subgraphs/name/metaplaygames/ido-bsc-second",
        // wsUri:
        //   "wss://api.thegraph.com/subgraphs/name/metaplaygames/ido-bsc-second",
        httpUri: "",
        wsUri: ""
      },
      league: {
        // httpUri:
        //   'https://api.thegraph.com/subgraphs/name/metaplaygames/metaplay-league-bsc',
        httpUri: "",
        wsUri: ""
      }
    },
    secondsPerBlock: 3
  },
  [networkIds.BSCTEST]: {
    label: "Binance Testnet",
    url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    contracts: {
      lottery: "0xfc06C530C8e6E11f78FB5AC8A0A79153C9aE00cC",
      multicall: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576"
    },
    tier: {
      tier: "0x3258E5cF7d56878605d34430DACb04EfaA899803",
      point: "0xfC1E6d6C8d7B78d6cF0E217f04B6047E91827F9D",
      bnbbUsdt: "0x487F6D58d1f3579364C70DA3496105dc3d265e8B",
      staking: "0x846B36be13B4f09972e7102Ee6E09d801c853C61",
      stakingLong: "",
      bnbbUsdtLock: "0x7c48fFe90EE9BD019621d9C3B601BA217a65B35f"
    },
    etherscanUri: "https://testnet.bscscan.com/",
    thegraph: {
      lottery: {
        httpUri: "https://api.thegraph.com/subgraphs/name/polyplaygames/lottery-chapel",
        wsUri: "wss://api.thegraph.com/subgraphs/name/polyplaygames/lottery-chapel"
      },
      ido: {
        httpUri: "https://api.thegraph.com/subgraphs/name/polyplaygames/ido-chapel",
        wsUri: "wss://api.thegraph.com/subgraphs/name/polyplaygames/ido-chapel"
      },
      league: {
        httpUri: "https://api.thegraph.com/subgraphs/name/metaplaygames/metaplay-league-chapel",
        wsUri: ""
      }
    },
    secondsPerBlock: 3
  },
  [networkIds.HARMONY]: {
    label: "Harmony Mainnet",
    url: "https://api.harmony.one",
    contracts: {
      lottery: "",
      multicall: ""
    },
    tier: {
      tier: "",
      point: "",
      bnbbUsdt: "",
      staking: "",
      bnbbUsdtLock: "",
      stakingLong: ""
    },
    etherscanUri: "https://explorer.harmony.one/",
    thegraph: {
      lottery: {
        httpUri: "",
        wsUri: ""
      },
      ido: {
        httpUri: "",
        wsUri: ""
      },
      league: {
        httpUri: "",
        wsUri: ""
      }
    },
    secondsPerBlock: 2
  },
  [networkIds.MAINNET]: {
    label: "Ethereum Mainnet",
    url: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    contracts: {
      lottery: "",
      multicall: "0xd635772fdf9d89e308a0bbe12e6959c09346ea24"
    },
    tier: {
      tier: "",
      point: "",
      bnbbUsdt: "",
      staking: "",
      stakingLong: "",
      bnbbUsdtLock: ""
    },
    etherscanUri: "https://etherscan.io/",
    thegraph: {
      lottery: {
        httpUri: "",
        wsUri: ""
      },
      ido: {
        httpUri: "https://api.thegraph.com/subgraphs/name/metaplaygames/metaplay-ido-eth",
        wsUri: "wss://api.thegraph.com/subgraphs/name/metaplaygames/metaplay-ido-eth"
      },
      league: {
        httpUri: "",
        wsUri: ""
      }
    },
    secondsPerBlock: 2
  }
};

const knownTokens: { [K in KnownToken]: IKnownTokenData } = {
  bnbb: {
    name: "BNBBALL",
    symbol: "BNBB",
    addresses: {
      [networkIds.BSC]: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      [networkIds.BSCTEST]: "",
      [networkIds.HARMONY]: "",
      [networkIds.MAINNET]: ""
    },
    decimals: 18,
    image: "/assets/tokens/bnbball.png"
  },
  usdt: {
    name: "Tether USD",
    symbol: "usdt",
    addresses: {
      [networkIds.BSC]: "0x55d398326f99059fF775485246999027B3197955",
      [networkIds.BSCTEST]: "",
      [networkIds.HARMONY]: "",
      [networkIds.MAINNET]: ""
    },
    decimals: 18,
    image: "/assets/tokens/usdt.png"
  },
  "bnbb-usdt": {
    name: "BNBB-USDT LP Token",
    symbol: "bnbb-usdt",
    addresses: {
      [networkIds.BSC]: "0x01bd616e4f8b3ab01d4406a56d13e7efabbba2d4",
      [networkIds.BSCTEST]: "",
      [networkIds.HARMONY]: "",
      [networkIds.MAINNET]: ""
    },
    token0: {
      [networkIds.BSC]: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      [networkIds.BSCTEST]: "",
      [networkIds.HARMONY]: "",
      [networkIds.MAINNET]: ""
    },
    token1: {
      [networkIds.BSC]: "0x55d398326f99059fF775485246999027B3197955",
      [networkIds.BSCTEST]: "",
      [networkIds.HARMONY]: "",
      [networkIds.MAINNET]: ""
    },
    decimals: 18,
    image: "/assets/tokens/usdt.png"
  }
};

export const supportedNetworkIds = Object.keys(networks).map(Number) as NetworkId[];

export const supportedNetworkURLs = entries(networks).reduce<{
  [networkId: number]: string;
}>(
  (acc, [networkId, network]) => ({
    ...acc,
    [networkId]: network.url
  }),
  {}
);

const validNetworkId = (networkId: number): networkId is NetworkId => {
  return networks[networkId as NetworkId] !== undefined;
};

export const getToken = (tokenId: KnownToken, networkId?: number): IToken => {
  const token = knownTokens[tokenId];

  if (!token) {
    throw new Error(`Unsupported token id: '${tokenId}'`);
  }
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }
  return {
    name: token.name,
    symbol: token.symbol,
    decimals: token.decimals,
    address: token.addresses[fNetworkId],
    image: token.image,
    token0: token.token0 ? token.token0[fNetworkId] : undefined,
    token1: token.token1 ? token.token1[fNetworkId] : undefined
  };
};

export const getTokenFromAddress = (address: string, chianId?: number): IToken => {
  const networkId = chianId || DEFAULT_NETWORK_ID;

  if (!validNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }

  for (const token of Object.values(knownTokens)) {
    const tokenAddress = token.addresses[networkId];

    // token might not be supported in the current network
    if (!tokenAddress) {
      continue;
    }

    if (tokenAddress.toLowerCase() === address.toLowerCase()) {
      return {
        name: token.name,
        symbol: token.symbol,
        decimals: token.decimals,
        address: tokenAddress,
        image: token.image,
        token0: token.token0 ? token.token0[networkId] : undefined,
        token1: token.token1 ? token.token1[networkId] : undefined
      };
    }
  }

  throw new Error(`Couldn't find token with address '${address}' in network '${networkId}'`);
};

export const getEtherscanUri = (networkId?: number): string => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }

  return networks[fNetworkId].etherscanUri;
};

export const getContractAddress = (contract: KnownContracts, networkId?: number): string => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }
  return networks[fNetworkId].contracts[contract];
};

export const getGraphInfo = (type: KnownTheGraph, networkId?: number): { httpUri: string; wsUri: string } => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }
  return networks[fNetworkId].thegraph[type];
};

export const getBlocksPerYear = (networkId?: number): number => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }
  const secondsPerBlock = networks[fNetworkId].secondsPerBlock;

  return Math.floor((365 * 24 * 60 * 60) / secondsPerBlock);
};

export const DefaultReadonlyProvider = new providers.JsonRpcProvider(
  networks[DEFAULT_NETWORK_ID].url,
  DEFAULT_NETWORK_ID
);

const tokenStakings: { [K in NetworkId]: ITokenStaking[] } = {
  [networkIds.BSC]: [
    {
      name: "BNBB (30 days)",
      address: "0xBE62B9A68831d6A0dbC9F99E672c25C102d4ef10",
      token: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      pId: ZERO,
      rewardToken: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      buyLink: "https://pancakeswap.finance/swap?outputCurrency=0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      apr: 30.12
    },
    {
      name: "BNBB (90 days)",
      address: "0xb1d7b6bac301F77e17EA128085B8D36f53Fd12b3",
      token: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      pId: ZERO,
      rewardToken: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      buyLink: "https://pancakeswap.finance/swap?outputCurrency=0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      apr: 53.56
    }
  ],
  [networkIds.BSCTEST]: [],
  [networkIds.HARMONY]: [],
  [networkIds.MAINNET]: []
};
export const getTokenStakings = (networkId?: number): ITokenStaking[] => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }

  return tokenStakings[fNetworkId];
};

const lpMinings: { [K in NetworkId]: ILPMining[] } = {
  [networkIds.BSC]: [
    {
      name: "BNBB-USDT",
      address: "0x762Bdcb38eF3Eb94D8cd6a8024554884c0e9dA67",
      lpToken: "0x01bd616e4f8b3ab01d4406a56d13e7efabbba2d4",
      rewardToken: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      token0: "0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
      token1: "0x55d398326f99059fF775485246999027B3197955",
      pId: ZERO,
      buyLink:
        "https://pancakeswap.finance/v2/add/0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409/0x55d398326f99059fF775485246999027B3197955",
      apr: 169.52
    }
  ],
  [networkIds.BSCTEST]: [],
  [networkIds.HARMONY]: [],
  [networkIds.MAINNET]: []
};
export const getLPMinings = (networkId?: number): ILPMining[] => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }

  return lpMinings[fNetworkId];
};

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum;
  if (provider) {
    const chainId = DEFAULT_NETWORK_ID;
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: networks[DEFAULT_NETWORK_ID].label,
            nativeCurrency: {
              name: "BNB",
              symbol: "bnb",
              decimals: 18
            },
            rpcUrls: [networks[chainId].url],
            blockExplorerUrls: [networks[chainId].etherscanUri]
          }
        ]
      });
      return true;
    } catch (error) {
      alert(JSON.stringify(error));
      console.error(error);
      return false;
    }
  } else {
    alert("shit");
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
    return false;
  }
};

export const getTierFactor = (networkId?: number): ITierFactor => {
  const fNetworkId = networkId || DEFAULT_NETWORK_ID;
  if (!validNetworkId(fNetworkId)) {
    throw new Error(`Unsupported network id: '${fNetworkId}'`);
  }

  return networks[fNetworkId].tier;
};
