import axios from "axios";
import { getGraphInfo } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { useEffect, useState } from "react";
import { ILeagueDetails } from "types";
import LeagueAbi from "abis/League.json";
import Erc20Abi from "abis/Erc20.json";
import { useServices } from "helpers/useServices";

interface IState {
  loading: boolean;
  data?: ILeagueDetails;
}

export const useLeague = (id: string) => {
  const { networkId } = useConnectedWeb3Context();
  const idoGraphHttpEndPoint = getGraphInfo("ido", networkId).httpUri;
  const [state, setState] = useState<IState>({ loading: true });
  const { multicall } = useServices();

  const loadIDO = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const calls = [
        "token",
        "entryAmount",
        "startTime",
        "closeTime",
        "currentChoiceId",
        "finalNumber",
        "isFinalized",
        "maxChoiceNumber",
        "expectLength",
        "meta",
        "finalChoiceLength",
        "winningNumber"
      ].map(method => ({
        name: method,
        address: id,
        params: []
      }));
      const [
        [token],
        [entryAmount],
        [startTime],
        [closeTime],
        [currentChoiceId],
        [finalNumber],
        [isFinalized],
        [maxChoiceNumber],
        [expectLength],
        [meta],
        [finalChoiceLength],
        [winningNumber]
      ] = await multicall.multicallv2(LeagueAbi, calls, {
        requireSuccess: false
      });

      const metaData = (await axios.get(meta)).data;

      const balanceCalls = [{ address: token, name: "balanceOf", params: [id] }];
      const [[prizeAmount]] = await multicall.multicallv2(Erc20Abi, balanceCalls);

      const league: ILeagueDetails = {
        address: id,
        entryAmount,
        token,
        startTime: startTime.toNumber(),
        closeTime: closeTime.toNumber(),
        currentChoiceId,
        finalNumber,
        isFinalized,
        maxChoiceNumber: maxChoiceNumber.toNumber(),
        expectLength: expectLength.toNumber(),
        meta,
        metaData: metaData,
        winningNumber,
        finalChoiceLength: finalChoiceLength.toNumber(),
        prizeAmount
      };
      setState(prev => ({ ...prev, loading: false, data: league }));
    } catch (error) {
      console.error(error);
      setState(() => ({ loading: false }));
    }
  };

  useEffect(() => {
    loadIDO();

    let interval = setInterval(loadIDO, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [idoGraphHttpEndPoint, id]);

  return { ...state, load: loadIDO };
};
