import { DefaultReadonlyProvider } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { FetchStatus } from "utils/enums";
import { useEffect, useState } from "react";
import { ERC20Service } from "services/erc20";
import { ZERO } from "utils/number";
import { BigNumber } from "ethers";
import { NULL_ADDRESS } from "config/constants";

type UseTokenBalanceState = {
  balance: BigNumber;
  fetchStatus: FetchStatus;
};

export const useTokenBalance = (tokenAddress: string) => {
  const { NOT_FETCHED, SUCCESS, FAILED } = FetchStatus;
  const [balanceState, setBalanceState] = useState<UseTokenBalanceState>({
    balance: ZERO,
    fetchStatus: NOT_FETCHED
  });
  const { account, library: provider } = useConnectedWeb3Context();

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        if (tokenAddress === NULL_ADDRESS) {
          const res = await provider?.getBalance(account || "");
          setBalanceState({
            balance: res || ZERO,
            fetchStatus: SUCCESS
          });
        } else {
          const contract = new ERC20Service(provider || DefaultReadonlyProvider, account, tokenAddress);
          const res = await contract.getBalanceOf(account || "");
          setBalanceState({
            balance: res,
            fetchStatus: SUCCESS
          });
        }
      } catch (e) {
        console.error(e);
        setBalanceState(prev => ({
          ...prev,
          fetchStatus: FAILED
        }));
      }
    };

    if (account) {
      fetchBalance();
    }
  }, [account, tokenAddress, SUCCESS, FAILED]);

  return balanceState;
};
