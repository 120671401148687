import { getGraphInfo, networkIds } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { useEffect, useState } from "react";
import { ILeague } from "types";
import { LeagueSection } from "utils/enums";
import { getCurrentTimeStamp } from "utils/ido";
import { fetchQuery } from "utils/thegraph";

const query = (mode: LeagueSection) => {
  switch (mode) {
    case LeagueSection.Upcoming:
      return `
  query ($first: Int!, $skip: Int!, $timestamp: String!) {
    leagues(first: $first, skip: $skip, orderBy: startTime, orderDirection: asc, where: {startTime_gte: $timestamp,cancelled: false}) {
      id
      address
    }
  }
`;
    case LeagueSection.Ongoing:
      return `
  query ($first: Int!, $skip: Int!, $timestamp: String!) {
    leagues(first: $first, skip: $skip, orderBy: startTime, orderDirection: desc, where: {closeTime_gte: $timestamp, startTime_lte: $timestamp,cancelled: false}) {
      id
      address
    }
  }
`;
    case LeagueSection.Ended:
      return `
  query ($first: Int!, $skip: Int!, $timestamp: String!) {
    leagues(first: $first, skip: $skip, orderBy: startTime, orderDirection: desc, where: {closeTime_lt: $timestamp, isFinalized: false,cancelled: false}) {
      id
      address
    }
  }
`;
    default:
      return `
  query ($first: Int!, $skip: Int!, $timestamp: String!) {
    leagues(first: $first, skip: $skip, orderBy: closeTime, orderDirection: desc, where: {cancelled: false,isFinalized: true}) {
      id
      address
    }
  }
`;
  }
};

const wrangleLeague = (league: any) =>
  ({
    ...league
  } as ILeague);

interface IState {
  loading: boolean;
  data: ILeague[];
  hasMore: boolean;
}

const PerPage = 6;

export const useLeagues = (mode: LeagueSection) => {
  const { networkId } = useConnectedWeb3Context();
  const leagueGraphHttpEndPoint = getGraphInfo("league", networkId).httpUri;
  const [state, setState] = useState<IState>({
    loading: true,
    data: [],
    hasMore: false
  });

  const loadLeagues = async (skip: number) => {
    // if (networkId === networkIds.BSCTEST) {
    //   setState((prev) => ({ ...prev, loading: false }));
    //   return;
    // }
    setState(prev => ({ ...prev, loading: true }));
    try {
      const currentTimeStamp = getCurrentTimeStamp();
      const result = (
        await fetchQuery(
          query(mode),
          {
            skip,
            first: PerPage + 1,
            timestamp: currentTimeStamp.toString()
          },
          leagueGraphHttpEndPoint
        )
      ).data;
      if (result.data && result.data.leagues) {
        setState(prev => ({
          ...prev,
          loading: false,
          data:
            skip === 0
              ? result.data.leagues.map((league: any) => wrangleLeague(league)).slice(0, PerPage)
              : [...state.data, ...result.data.leagues.map((league: any) => wrangleLeague(league)).slice(0, PerPage)],
          hasMore: result.data.leagues.length > PerPage
        }));
      } else {
        setState(prev => ({ ...prev, loading: false, data: [] }));
      }
    } catch (error) {
      setState(prev => ({ ...prev, loading: false, data: [] }));
    }
  };

  const loadMore = async () => {
    await loadLeagues(state.data.length);
  };

  useEffect(() => {
    setState(prev => ({ ...prev, data: [], hasMore: false }));
    loadLeagues(0);
  }, [networkId]);

  return { ...state, load: loadLeagues, loadMore };
};
