import { Button, makeStyles } from "@material-ui/core";
import { useConnectedWeb3Context } from "contexts";
import React from "react";

import { ReactComponent as WalletIcon } from "assets/svg/wallet.svg";

const useStyles = makeStyles(theme => ({
  root: {}
}));

export const AccountInfo = () => {
  const classes = useStyles();
  const { account, onDisconnect, setWalletConnectModalOpened } = useConnectedWeb3Context();
  const isConnected = account;
  return (
    <div className={classes.root}>
      {isConnected ? (
        <Button color="primary" onClick={() => onDisconnect()} variant="outlined">
          <WalletIcon />
          &nbsp;
          <span>0x...{account?.substr(-4)}</span>
        </Button>
      ) : (
        <>
          <Button
            color="primary"
            onClick={() =>
              window.open(
                "https://pancakeswap.finance/swap?outputCurrency=0xA0D0013E8faabE703fD970a5ae6A42CAA0EA2409",
                "_blank"
              )
            }
          >
            BUY TOKEN
          </Button>
          <Button color="primary" onClick={() => setWalletConnectModalOpened(true)} variant="contained">
            Connect
          </Button>
        </>
      )}
    </div>
  );
};
