import axios from "axios";
import { getGraphInfo } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { IIDODetails, IRoundData } from "types";
import { fetchQuery } from "utils/thegraph";
import IDOv4Abi from "abis/idov4.json";
import IDOSecAbi from "abis/idoSec.json";
import { useServices } from "helpers/useServices";

const query = `
  query ($id: ID!) {
    ido(id: $id) {
      id
      version
    }
  }
`;

interface IState {
  loading: boolean;
  data?: IIDODetails;
}

export const useIDO = (id: string) => {
  const { networkId } = useConnectedWeb3Context();
  const idoGraphHttpEndPoint = getGraphInfo("ido", networkId).httpUri;
  const [state, setState] = useState<IState>({ loading: true });
  const { multicall } = useServices();

  const loadIDO = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const result = (await fetchQuery(query, { id }, idoGraphHttpEndPoint)).data;
      if (result.data && result.data.ido) {
        const isSec = result.data.ido.version === "sec";

        const calls = [
          "owner",
          "startTime",
          "endTime",
          "claimTime",
          "saleToken",
          "saleTarget",
          "fundToken",
          "fundTarget",
          "fundRaised",
          "totalReleased",
          isSec ? "fcfsAmount" : "baseAmount",
          "minFundAmount",
          "meta",
          "cliffTime",
          "distributePercentAtClaim",
          "vestingDuration",
          "vestingPeriodicity",
          "getFundersCount"
        ].map(method => ({
          name: method,
          address: id,
          params: []
        }));
        const [
          [creator],
          [startTime],
          [endTime],
          [claimTime],
          [saleToken],
          [saleTarget],
          [fundToken],
          [fundTarget],
          [fundRaised],
          [totalReleased],
          [baseAmount],
          [minFundAmount],
          [meta],
          [cliffTime],
          [distributePercentAtClaim],
          [vestingDuration],
          [vestingPeriodicity],
          [totalFunders]
        ] = await multicall.multicallv2(isSec ? IDOSecAbi : IDOv4Abi, calls, {
          requireSuccess: false
        });

        let roundData: IRoundData[] = [];

        if (isSec) {
          const roundCalls = [1, 2].map(roundNumber => ({
            name: "roundsFundRaised",
            address: id,
            params: [roundNumber]
          }));
          const roundResponse = await multicall.multicallv2(IDOv4Abi, roundCalls, {
            requireSuccess: false
          });

          roundData = roundResponse.map((info: BigNumber[], index: number) =>
            info && info.length > 0
              ? ({
                  roundId: BigNumber.from(index + 1),
                  fundRaised: info[0],
                  saleRaised: info[0].mul(saleTarget).div(fundTarget)
                } as IRoundData)
              : null
          );
        } else {
          const roundCalls = [1, 2, 3, 4, 5, 6].map(roundNumber => ({
            name: "roundsFundRaised",
            address: id,
            params: [roundNumber]
          }));
          const roundResponse = await multicall.multicallv2(IDOv4Abi, roundCalls, {
            requireSuccess: false
          });

          roundData = roundResponse.map((info: BigNumber[], index: number) =>
            info && info.length > 0
              ? ({
                  roundId: BigNumber.from(index + 1),
                  fundRaised: info[0],
                  saleRaised: info[0].mul(saleTarget).div(fundTarget)
                } as IRoundData)
              : null
          );
        }

        const ido = {
          ...result.data.ido,
          address: id,
          creator,
          startTime: startTime.toNumber(),
          endTime: endTime.toNumber(),
          claimTime: claimTime.toNumber(),
          saleToken,
          saleTarget,
          fundToken,
          fundTarget,
          fundRaised,
          totalReleased,
          baseAmount,
          minFundAmount,
          meta,
          cliffTime: cliffTime.toNumber(),
          distributePercentAtClaim: distributePercentAtClaim.toNumber(),
          vestingDuration: vestingDuration.toNumber(),
          vestingPeriodicity: vestingPeriodicity.toNumber(),
          totalFunders,
          rounds: roundData,
          saleRaised: fundRaised.mul(saleTarget).div(fundTarget)
        };
        try {
          const metadata = (await axios.get(ido.meta)).data;
          setState(prev => ({
            ...prev,
            loading: false,
            data: {
              ...ido,
              meta: metadata
            }
          }));
        } catch (error) {
          setState(prev => ({
            ...prev,
            loading: false,
            data: {
              ...ido,
              meta: undefined
            }
          }));
        }
      } else {
        setState(() => ({ loading: false }));
      }
    } catch (error) {
      console.error(error);
      setState(() => ({ loading: false }));
    }
  };

  useEffect(() => {
    loadIDO();

    let interval = setInterval(loadIDO, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [idoGraphHttpEndPoint, id]);

  return { ...state, load: loadIDO };
};
