import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCountDownTimer } from "helpers";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  item: {
    "& + &": {
      marginLeft: 8
    },
    color: theme.colors.primary,
    textTransform: "lowercase"
  },
  value: {},
  label: {}
}));

interface IProps {
  destTimeStamp: number;
  onReached: () => void;
  className?: string;
}

export const CountDownTimer = (props: IProps) => {
  const { destTimeStamp, onReached } = props;
  const classes = useStyles();
  const { diff, hour, min, day, sec } = useCountDownTimer(destTimeStamp);

  useEffect(() => {
    if (diff === 0) {
      onReached();
    }
  }, [diff, onReached]);

  const items = [];

  if (day > 0) {
    items.push({ value: day, label: "D" });
    items.push({ value: hour, label: "H" });
    items.push({ value: min, label: "M" });
    items.push({ value: sec, label: "S" });
  } else if (hour > 0) {
    items.push({ value: hour, label: "H" });
    items.push({ value: min, label: "M" });
    items.push({ value: sec, label: "S" });
  } else if (min > 0) {
    items.push({ value: min, label: "M" });
    items.push({ value: sec, label: "S" });
  } else {
    items.push({ value: sec, label: "S" });
  }

  return (
    <div className={clsx(classes.root, props.className)}>
      {items.map(item => (
        <div className={classes.item} key={item.label}>
          <span className={classes.value}>{item.value}</span>
          <span className={classes.label}>{item.label}</span>
        </div>
      ))}
    </div>
  );
};
