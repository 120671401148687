import { BigNumber, Contract, Wallet, ethers } from "ethers";
import { Maybe } from "types";
import pointAbi from "abis/point.json";

class PointService {
  provider: any;
  contract: Contract;

  constructor(provider: any, signerAddress: Maybe<string>, addr: string) {
    this.provider = provider;
    if (signerAddress) {
      const signer: Wallet = provider.getSigner();
      this.contract = new ethers.Contract(addr, pointAbi, provider).connect(signer);
    } else {
      this.contract = new ethers.Contract(addr, pointAbi, provider);
    }
  }

  get address(): string {
    return this.contract.address;
  }
  async getPoint(address: string): Promise<BigNumber> {
    return this.contract.getPoint(address);
  }
}

export { PointService };
