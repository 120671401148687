import { useConnectedWeb3Context } from "contexts";
import { useServices } from "helpers/useServices";
import { useEffect, useState } from "react";
import { ITokenStaking, ITokenStakingDetails } from "types";
import stakingAbi from "abis/staking.json";
interface IState {
  loading: boolean;
  data?: ITokenStakingDetails;
}

export const useStakingPool = (props: ITokenStaking): IState & { reload: () => Promise<void> } => {
  const [state, setState] = useState<IState>({ loading: true });
  const { networkId } = useConnectedWeb3Context();
  const { multicall } = useServices();

  const loadAll = async (clear = false) => {
    setState(prev => ({
      ...prev,
      loading: true,
      data: clear ? undefined : prev.data
    }));

    try {
      const poolCalls = [
        { name: "poolInfo", address: props.address, params: [props.pId] },
        { name: "tokenPerBlock", address: props.address, params: [] },
        { name: "emergencyWithdrawFee", address: props.address, params: [] }
      ];

      const [poolInfo, [tokenPerBlock], [emergencyWithdrawFee]] = await multicall.multicallv2(stakingAbi, poolCalls, {
        requireSuccess: false
      });

      console.log("poolInfo:", poolInfo[0]);

      setState(prev => ({
        ...prev,
        loading: false,
        data: {
          ...props,
          allocPoint: poolInfo[0],
          lastRewardBlock: poolInfo[1],
          accTokenPerShare: poolInfo[2],
          depositedAmount: poolInfo[3],
          rewardsAmount: poolInfo[4],
          lockupDuration: poolInfo[5],
          tokenPerBlock,
          emergencyWithdrawFee
        }
      }));
    } catch (error) {
      setState(() => ({ loading: false }));
    }
  };

  useEffect(() => {
    loadAll(true);
    let interval = setInterval(loadAll, 20000);
    return () => {
      clearInterval(interval);
    };
  }, [networkId, props.address]);

  return { ...state, reload: loadAll };
};
