import { makeStyles, Typography } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import clsx from "clsx";
import { transparentize } from "polished";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: transparentize(0.3, theme.colors.neutralLighter)
  },
  value: { fontSize: 18, fontWeight: 600, color: theme.colors.primary }
}));

interface IProps {
  label: string;
  value: string;
  className?: string;
}

export const InfoRow = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <Typography className={classes.label}>{props.label}</Typography>
      <Typography className={classes.value}>{props.value}</Typography>
    </div>
  );
};
