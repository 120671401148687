import { DefaultReadonlyProvider } from "config/networks";
import { useConnectedWeb3Context } from "contexts";
import { useServices } from "helpers/useServices";
import { useEffect, useState } from "react";
import { ERC20Service } from "services/erc20";
import { ILPMining, ILPMiningDetails } from "types";
import LpMiningAbi from "abis/lpMining.json";

interface IState {
  loading: boolean;
  data?: ILPMiningDetails;
}

export const useLPMining = (props: ILPMining): IState & { reload: () => Promise<void> } => {
  const [state, setState] = useState<IState>({ loading: true });
  const { networkId, library: provider, account } = useConnectedWeb3Context();
  const { multicall } = useServices();

  const loadAll = async (clear = false) => {
    setState(prev => ({
      ...prev,
      loading: true,
      data: clear ? undefined : prev.data
    }));

    const erc20 = new ERC20Service(provider || DefaultReadonlyProvider, account, props.lpToken);

    try {
      const depositedAmount = await erc20.getBalanceOf(props.address);

      const poolCalls = [
        { name: "poolInfo", address: props.address, params: [props.pId] },
        { name: "tokenPerBlock", address: props.address, params: [] },
        { name: "emergencyWithdrawFee", address: props.address, params: [] }
      ];

      const [poolInfo, [tokenPerBlock], [emergencyWithdrawFee]] = await multicall.multicallv2(LpMiningAbi, poolCalls, {
        requireSuccess: false
      });

      setState(prev => ({
        ...prev,
        loading: false,
        data: {
          ...props,
          allocPoint: poolInfo[1],
          lastRewardBlock: poolInfo[2],
          accTokenPerShare: poolInfo[3],
          depositedAmount,
          lockupDuration: poolInfo[4],
          tokenPerBlock,
          emergencyWithdrawFee
        }
      }));
    } catch (error) {
      setState(() => ({ loading: false }));
    }
  };

  useEffect(() => {
    loadAll(true);
    let interval = setInterval(loadAll, 20000);
    return () => {
      clearInterval(interval);
    };
  }, [networkId, props.address]);

  return { ...state, reload: loadAll };
};
