import { ETHEME } from "utils/enums";

const colors = [
  {
    name: ETHEME.Black,
    colors: {
      transparent: "#0000",
      default: "#000",
      reverse: "#FFF",
      white: "#fff",
      black: "#000",
      black1: "#121003",
      primary: "#f8dc2a",
      secondary: "#2A0F0C",
      third: "#996100",
      fourth: "#0F0F0F",
      fifth: "rgb(118, 69, 217)",
      sixth: "rgb(31, 199, 212)",
      burn: "rgb(237, 75, 158)",
      gray1: "#1c1c1c",
      gray2: "#9A9993",
      error: "#c33",
      neutralDarker: "#000000",
      neutralDark: "#141316",
      neutral: "#1d1d20",
      neutralLight: "#2e2e33",
      neutralLighter: "#EFEFEF",
      green: "#2A9D8F",
      yellow: "#E9C46A",
      blue: "#8ecae6"
      // neutralLightest:"",
    }
  },
  {
    name: ETHEME.White,
    colors: {
      transparent: "#0000",
      default: "#fff",
      reverse: "#000",
      white: "#fff",
      black: "#000",
      black1: "#121003",
      primary: "#f8dc2a",
      secondary: "#2A0F0C",
      third: "#996100",
      fourth: "#0F0F0F",
      fifth: "rgb(118, 69, 217)",
      sixth: "rgb(31, 199, 212)",
      burn: "rgb(237, 75, 158)",
      gray1: "#1c1c1c",
      gray2: "#9A9993",
      error: "#c33",
      neutralDarker: "#000000",
      neutralDark: "#141316",
      neutral: "#1d1d20",
      neutralLight: "#2e2e33",
      neutralLighter: "#EFEFEF",
      green: "#2A9D8F",
      yellow: "#E9C46A",
      blue: "#8ecae6"
      // neutralLightest:"",
    }
  }
];

export default colors;
