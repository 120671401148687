import { ReactComponent as WalletIcon } from "assets/menu/wallet.svg";
import { ReactComponent as StakingIcon } from "assets/menu/staking.svg";
import { ReactComponent as LotteryIcon } from "assets/menu/lottery.svg";
import { ReactComponent as TournamentIcon } from "assets/menu/tournament.svg";
import { ReactComponent as EntryLockIcon } from "assets/menu/entry-lock.svg";
import { ReactComponent as PlayPadIcon } from "assets/menu/playpad.svg";
import { ReactComponent as SupportIcon } from "assets/menu/support.svg";
import { ReactComponent as FaqIcon } from "assets/menu/faq.svg";
import { ReactComponent as SolanaIcon } from "assets/menu/sol.svg";
import { ReactComponent as LeagueIcon } from "assets/menu/league.svg";
import { ReactComponent as ClaimIcon } from "assets/menu/claim.svg";

import { ReactComponent as DiscordIcon } from "assets/social/discord.svg";
import { ReactComponent as DiscordHandleIcon } from "assets/social/discord-small.svg";
import { ReactComponent as InstagramIcon } from "assets/social/instagram.svg";
import { ReactComponent as MediumIcon } from "assets/social/medium.svg";
import { ReactComponent as TelegramIcon } from "assets/social/telegram.svg";
import { ReactComponent as TwitterIcon } from "assets/social/twitter.svg";
import { ReactComponent as YoutubeIcon } from "assets/social/youtube.svg";

import { ConnectorNames } from "utils/enums";

export const STORAGE_KEY_CONNECTOR = "CONNECTOR";

export const LOGGER_ID = "BNBBALL-APP";

export const DEFAULT_NETWORK_ID = 56;

export const WALLET_ICONS: { [key in ConnectorNames]: string } = {
  [ConnectorNames.Injected]: "/assets/wallets/metamask-color.svg",
  [ConnectorNames.TrustWallet]: "/assets/wallets/trust-wallet.svg",
  [ConnectorNames.WalletConnect]: "/assets/wallets/wallet-connect.svg",
  [ConnectorNames.TokenPocket]: "/assets/wallets/token-pocket.svg"
};

export const ETHER_DECIMAL = 18;

export const TICKET_LIMIT_PER_REQUEST = 2500;
export const NUM_ROUNDS_TO_CHECK_FOR_REWARDS = 3;
export const NUM_ROUNDS_TO_FETCH_FROM_NODES = 3;

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const IDOS_TO_HIDE = [
  "0xa621db6a0b34ff343df1616db8130547c403ba5f",
  "0x272153f40bda04ea45047b9a2aff0b50c27e0f81",
  "0xf02ca767156337a45a339b326b8afddc55e2f9c4",
  "0x13716e06eda1ff1012233b32fcab17c58efc6abb",
  "0xacb9f60cf28f8db2f7c4d4af4b0f8aef1a4d3d5d",
  "0xb04cd63f92d46e5aba359ac67b608395bec04baa",
  "0xc21e24b4e5e0392c58ac5f484e7bb21fc94f7c66",
  "0xc21e24b4e5e0392c58ac5f484e7bb21fc94f7c66",
  "0xd30f402376c9015d750628fe389db4adc8927402",
  "0x6138c9f6f1ecc8d894c0e0a6ae616c1d5320d6b7",
  "0x18ffcb30a2d845d0c996aef94846943fb0a076cf",
  "0xa4d2f7950f2e6d47c9bbd5e4e5de0dd6ad3e692a",
  "0xa2b5afb495d561de95034fd8a5c99a1f8f31c285",
  "0xee9a2f00540fc2b737890176ed7e39532f9f8392",
  "0x505da19b6826dd581fb1f38b06d45dfde814302f",
  "0x05feb7e46e8b196209c0870584498745f3e9f297",
  "0xac72e1a679098e7bf432c11fe9d8f0465c87be88",
  "0xcc36d66bdffdd83d5ef9eb70dc10111c7064bf97",
  "0x2adda56d9a448b8af514db25d4a102fa898a9af5",
  "0xc135b3f50cc0dea56a924874e73293fcf4bb1e13",
  "0x011bd404c482708f277df71e799b7854e1679251",
  "0x29122ca70d609c16f9b4e72827f8db5f45c8168d",
  "0x53db41db9d1918e8b8da0ad0ac71c35c86f2f375",
  "0x21b13e9d4a732518476194070ba6e22917900357",
  "0xaf938b30de4e1988ac9e9ec65d1d3cd75ea60d0f",
  "0x5e69064f1451750ec8c016de6fa2abf76cd0d45d",
  "0x6740cd9212274f3a99a18258791a9661b55d45df"
].map(e => e.toLowerCase());

export const SVG_ICONS = {
  wallet: WalletIcon,
  staking: StakingIcon,
  lottery: LotteryIcon,
  tournament: TournamentIcon,
  entryLock: EntryLockIcon,
  playPad: PlayPadIcon,
  support: SupportIcon,
  faq: FaqIcon,
  discord: DiscordIcon,
  discordHandle: DiscordHandleIcon,
  instagram: InstagramIcon,
  medium: MediumIcon,
  telegram: TelegramIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  solana: SolanaIcon,
  league: LeagueIcon,
  claim: ClaimIcon
};

export const NETWORK_NAMES: { [key: string]: string } = {
  "0x48c5584d134c67dca24fe546f8980f55d1b82580": "SOL",
  "0x05feb7e46e8b196209c0870584498745f3e9f297": "SOL",
  "0x011bd404c482708f277df71e799b7854e1679251": "Polygon",
  "0xc73cd47a50cea2b585611c04528f555ee69f9f69": "Ethereum",
  "0xa21c1142adb7fd9f99cfefe12327f528a4d73e2c": "SOL",
  "0xf339697d8d4d4d95f231c66b537876db4bbd786f": "SOL",
  "0xe5c383cae855a127751f889f3393a44e5a29edee": "SOL"
};

export const REFUND_CONTRACTS: { [key: string]: string } = {
  "0x72768d4bf5e5a43cbf0136655ac4e4245e6de8a2": "0xADEde614946e3B1598101FB7A3fDefD51611f4E2",
  "0x48c5584d134c67dca24fe546f8980f55d1b82580": "0xDc3a91B3548F97EB8daD3f1D5cDD79D9dd0DE17F"
};

export const CLAIM_LINKS: { [key: string]: string } = {
  "0x7cf2540f9b657c839e1545c71f8bafd66c2759e3": "https://app.monster-battle.games/#/launchpad/claim"
};

export const SOL_BSC_BACKEND_SERVER = "https://link-sol.meta-play.app/";
// export const SOL_BSC_BACKEND_SERVER = "http://10.4.1.2:4000/";

export const LEAGUE_CLIAM_SIZE_PER_TX = 100;
