import { BigNumber, Contract, Wallet, ethers } from "ethers";
import { Maybe } from "types";
import stakingAbi from "abis/staking.json";

class StakingService {
  provider: any;
  contract: Contract;

  constructor(provider: any, signerAddress: Maybe<string>, addr: string) {
    this.provider = provider;
    if (signerAddress) {
      const signer: Wallet = provider.getSigner();
      this.contract = new ethers.Contract(addr, stakingAbi, provider).connect(signer);
    } else {
      this.contract = new ethers.Contract(addr, stakingAbi, provider);
    }
  }

  get address(): string {
    return this.contract.address;
  }

  async token() {
    return this.contract.token();
  }

  async tokenPerBlock() {
    return this.contract.tokenPerBlock();
  }

  async emergencyWithdrawFee() {
    return this.contract.emergencyWithdrawFee();
  }

  async getDepositedAmount(address: string): Promise<BigNumber> {
    return this.contract.getDepositedAmount(address);
  }

  async poolInfo(pId: BigNumber) {
    return this.contract.poolInfo(pId);
  }

  async userInfo(pId: BigNumber, address: string) {
    return this.contract.userInfo(pId, address);
  }

  async pendingRewards(pId: BigNumber, address: string) {
    return this.contract.pendingRewards(pId, address);
  }

  async depositEstimateGas(pId: BigNumber, amount: BigNumber) {
    return this.contract.estimateGas.deposit(pId, amount);
  }

  async deposit(pId: BigNumber, amount: BigNumber, gasPrice: BigNumber, gasLimit: BigNumber) {
    const transactionObject = await this.contract.deposit(pId, amount, {
      gasLimit,
      gasPrice
    });
    console.log(`Deposit transaction hash: ${transactionObject.hash}`);
    return transactionObject.hash;
  }

  async withdrawEstimateGas(pId: BigNumber, amount: BigNumber, withRewards: boolean) {
    return this.contract.estimateGas.withdraw(pId, amount, withRewards);
  }

  async withdraw(pId: BigNumber, amount: BigNumber, withRewards: boolean, gasPrice: BigNumber, gasLimit: BigNumber) {
    const transactionObject = await this.contract.withdraw(pId, amount, withRewards, { gasLimit, gasPrice });
    console.log(`Withdraw transaction hash: ${transactionObject.hash}`);
    return transactionObject.hash;
  }

  async emergencyWithdrawEstimateGas(pId: BigNumber, amount: BigNumber) {
    return this.contract.estimateGas.emergencyWithdraw(pId, amount);
  }

  async emergencyWithdraw(pId: BigNumber, amount: BigNumber, gasPrice: BigNumber, gasLimit: BigNumber) {
    const transactionObject = await this.contract.emergencyWithdraw(pId, amount, { gasLimit, gasPrice });
    console.log(`EmergencyWithdraw transaction hash: ${transactionObject.hash}`);
    return transactionObject.hash;
  }

  async claimEstimateGas(pId: BigNumber) {
    return this.contract.estimateGas.claim(pId);
  }

  async claim(pId: BigNumber, gasPrice: BigNumber, gasLimit: BigNumber) {
    const transactionObject = await this.contract.claim(pId, {
      gasLimit,
      gasPrice
    });
    console.log(`Claim transaction hash: ${transactionObject.hash}`);
    return transactionObject.hash;
  }
}

export { StakingService };
